import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import './index.scss';

interface Props {
  id?: string;
  label?: string;
  icon?: string;
  name: string;
  type: 'text' | 'email' | 'number' | 'password';
  placeholder: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  search?: boolean;
  value?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  labelClasses?: string;
  inputClasses?: string;
  onkeyup?: (event: any) => void;
  disabledIcon?: boolean;
}

export const Input = ({
  id,
  label,
  icon,
  name,
  type,
  placeholder,
  required,
  disabled,
  helperText,
  search,
  style,
  labelClasses,
  inputClasses
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <>
      {label && (
        <label
          className={classNames('input-lable', labelClasses)}
          htmlFor={id || name}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div className="text-input-container">
        {search ? (
          <i className="ri-search-line text-input-icon" />
        ) : (
          <i className={`${icon} text-input-icon`} />
        )}
        <input
          className={classNames(
            `text-input-field ${icon ? 'text-input-with-icon' : ''} ${
              search ? 'text-input-with-icon' : ''
            } ${required && meta.touched && meta.error ? 'input-error' : ''} ${
              disabled ? 'input-disabled' : ''
            }`,
            inputClasses
          )}
          id={id || name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          {...field}
        />
        {search && meta.value && (
          <i
            className="ri-close-line text-input-clear-icon"
            onClick={() => {
              setValue(meta.initialValue);
            }}
            role="presentation"
          />
        )}
      </div>
      {!meta.touched && !meta.error && helperText && (
        <div className="input-helper-text">{helperText}</div>
      )}
      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};

type SimpleProps = Omit<Props, 'helperText'>;

interface SimpleInputProps extends SimpleProps {
  noBackground?: boolean;
  maxLength?: number;
}

export const InputSimple = ({
  id,
  label,
  icon,
  name,
  type,
  placeholder,
  required,
  disabled,
  search,
  value = '',
  style,
  labelClasses,
  disabledIcon = false,
  inputClasses,
  onChange,
  onkeyup,
  noBackground,
  maxLength
}: SimpleInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const inputSimpleRef = useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    const ref = inputSimpleRef?.current && inputSimpleRef?.current;
    if (ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      {label && (
        <label
          className={classNames('input-lable', labelClasses)}
          htmlFor={id || name}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div className="text-input-container">
        {!disabledIcon && (
          <div>
            {search ? (
              <i
                className="ri-search-line text-input-icon"
                onClick={handleIconClick}
              />
            ) : (
              <i className={`${icon} text-input-icon`} />
            )}
          </div>
        )}

        <input
          className={classNames(
            `text-input-field ${icon ? 'text-input-with-icon' : ''} ${
              search ? 'text-input-with-icon' : ''
            }  ${disabled ? 'input-disabled' : ''}
            ${noBackground ? 'input-no-background' : ''}`,
            inputClasses
          )}
          id={id || name}
          type={type}
          ref={inputSimpleRef}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
            setInputValue(e.target.value);
          }}
          value={inputValue}
          style={style}
          onKeyUp={onkeyup}
          maxLength={maxLength}
        />
        {search && inputValue && (
          <i
            className="ri-close-line text-input-clear-icon"
            onClick={() => {
              setInputValue('');
              if (onChange) onChange('');
            }}
          />
        )}
      </div>
    </>
  );
};

Input.defaultProps = {
  id: undefined,
  label: undefined,
  icon: undefined,
  helperText: undefined,
  required: undefined,
  disabled: undefined,
  search: undefined,
  value: undefined,
  style: undefined,
  onChange: undefined,
  labelClasses: undefined,
  inputClasses: undefined,
  onkeyup: undefined
};
