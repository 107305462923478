import React from 'react';
import { Tabs as Ts } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import './index.scss';

interface TabsProps {
  tabIndex?: number;
  onSelect?: (index: number) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Tabs = ({ tabIndex, onSelect, disabled, children }: TabsProps) => {
  return (
    <Ts selectedIndex={tabIndex} onSelect={onSelect} disabled={disabled}>
      {children}
    </Ts>
  );
};

Tabs.defaultProps = {
  tabIndex: undefined,
  onSelect: undefined,
  disabled: undefined,
  children: undefined
};
