import React from 'react';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import './index.scss';

interface ITable {
  children: React.ReactNode | any;
  tableClassNames?: string;
}

interface IThead {
  children: React.ReactNode | any;
}

interface ITbody {
  children: React.ReactNode | any;
}

interface ITr {
  children: React.ReactNode | any;
  tableRowClassNames?: string;
}

interface ITh {
  children: React.ReactNode | any;
  tableHeaderClassNames?: string;
}

interface ITd {
  children: React.ReactNode | any;
  onClick?: () => void;
  tableDataClassNames?: string;
  isHighlight?: boolean;
  searchString?: string;
}

export const Table = ({ children, tableClassNames }: ITable) => {
  return (
    <table className={classNames('table', tableClassNames)}>{children}</table>
  );
};

export const Thead = ({ children }: IThead) => {
  return <thead>{children}</thead>;
};

export const Tbody = ({ children }: ITbody) => {
  return <tbody>{children}</tbody>;
};

export const TR = ({ children, tableRowClassNames }: ITr) => {
  return <tr className={classNames(tableRowClassNames)}>{children}</tr>;
};

export const TH = ({ children, tableHeaderClassNames }: ITh) => {
  return <th className={classNames(tableHeaderClassNames)}>{children}</th>;
};

export const TD = ({
  children,
  onClick,
  tableDataClassNames,
  isHighlight,
  searchString
}: ITd) => {
  return (
    <td
      className={classNames(tableDataClassNames)}
      onClick={onClick}
      // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
      role="presentation"
    >
      {isHighlight ? (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={searchString ? [searchString] : ['']}
          autoEscape
          textToHighlight={children}
        />
      ) : (
        children
      )}
    </td>
  );
};

Table.defaultProps = {
  tableClassNames: undefined
};

TR.defaultProps = {
  tableRowClassNames: undefined
};

TH.defaultProps = {
  tableHeaderClassNames: undefined
};

TD.defaultProps = {
  onClick: undefined,
  tableDataClassNames: undefined,
  isHighlight: undefined,
  searchString: undefined
};
