/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { AvatarIcon } from './user';
import './index.scss';

interface Props {
  size?: 'default' | 'large' | 'small';
  initial?: string;
  decoration?: 'one' | 'two' | 'three' | 'four' | 'five';
  label?: string;
  labelSecondary?: string;
  isHighLight?: boolean;
  searchString?: string;
}

export const Avatar = ({
  size = 'default',
  initial,
  decoration,
  label,
  labelSecondary,
  isHighLight,
  searchString
}: Props) => {
  const [valueDecoration, setValueDecoration] = useState(decoration);

  const getRandomDecoration = (randomNumber: number) => {
    switch (randomNumber) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
      case 5:
        return 'five';
      case 6:
        return 'one';
      case 7:
        return 'two';
      case 8:
        return 'three';
      case 9:
        return 'four';
      case 0:
        return 'five';

      default:
        return 'one';
    }
  };

  useEffect(() => {
    if (initial && !valueDecoration) {
      const splittInitialArr = initial.split('');
      let tempTotal = 0;
      splittInitialArr.map((letter) => {
        tempTotal += tempTotal + letter.charCodeAt(0);
      });

      setValueDecoration(getRandomDecoration(tempTotal % 10));
    }
  }, []);

  const getImgWidth = ({ size = 'default' }: Props) => {
    switch (size) {
      case 'large':
        return '37.44';
      case 'small':
        return '19.97';
      default:
        return '24.96';
    }
  };

  const getImgHeight = ({ size = 'default' }: Props) => {
    switch (size) {
      case 'large':
        return '42';
      case 'small':
        return '22.4';
      default:
        return '28';
    }
  };

  return (
    <div className="avatar-container">
      <div
        className={`avatar-${size} ${!initial ? `avatar-img-${size}` : ''} ${
          valueDecoration
            ? `${
                label || labelSecondary ? 'color-' : 'color-'
              }${valueDecoration}`
            : ''
        }`}
      >
        {initial ? (
          initial.toUpperCase()
        ) : (
          <AvatarIcon
            width={getImgWidth({
              size
            })}
            height={getImgHeight({
              size
            })}
          />
        )}
      </div>
      {label && labelSecondary && (
        <div className="labels">
          <span className="title-label">
            {isHighLight ? (
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={searchString ? [searchString] : ['']}
                autoEscape
                textToHighlight={label}
              />
            ) : (
              label
            )}
          </span>
          <span className="secondary-label">
            {isHighLight ? (
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={searchString ? [searchString] : ['']}
                autoEscape
                textToHighlight={labelSecondary}
              />
            ) : (
              labelSecondary
            )}
          </span>
        </div>
      )}
      {label && !labelSecondary && (
        <div className="labels">
          <span className="title-label">
            {isHighLight ? (
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={searchString ? [searchString] : ['']}
                autoEscape
                textToHighlight={label}
              />
            ) : (
              label
            )}
          </span>
        </div>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  size: undefined,
  initial: undefined,
  decoration: undefined,
  label: undefined,
  labelSecondary: undefined,
  isHighLight: undefined,
  searchString: undefined
};
