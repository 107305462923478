import { Button, HeaderAlertsInfo } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { loginAsAdmin } from 'store/features/Auth';

export const LoginAsUserContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedInAsUser = useAppSelector((state: RootState) => {
    return state.auth.isLogInAsUser;
  });
  const userDisplayName = useAppSelector((state: RootState) => {
    return state.auth.displayName;
  });

  // const LoginAsAdminBK = async () => {
  //   // eslint-disable-next-line no-undef
  //   const BASE_URL = process.env.REACT_APP_BK_BASE_URL;
  //   try {
  //     await fetch(`${BASE_URL}/mbkunimpersonate.action?origin=mbk`, {
  //       method: 'GET',
  //       credentials: 'include'
  //     }).catch(() => {
  //       console.log('error');
  //     });
  //   } catch (error) {
  //     console.log('error');
  //   }
  // };

  const loginAsAdminLocal = async () => {
    await dispatch(loginAsAdmin({}));
    // LoginAsAdminBK();

    setTimeout(() => {
      navigate('/');
      window.location.reload();

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 200);
  };

  return (
    <div>
      {isLoggedInAsUser === true && (
        <HeaderAlertsInfo>
          <p className="base-regular count-info">
            {`Du er nå logget inn som ${userDisplayName}. Husk å`}
          </p>
          <Button
            style={{ paddingLeft: '5px', paddingRight: '5px' }}
            label={` bytte tilbake `}
            type="link"
            onClick={loginAsAdminLocal}
          />
          <p className="base-regular count-info">
            til din egen bruker når du er ferdig.
          </p>
        </HeaderAlertsInfo>
      )}
    </div>
  );
};
