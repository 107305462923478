import React, { useEffect, useRef, useState } from 'react';
import { ISubMenu } from 'models/models';
import './index.scss';

interface Props {
  link: string;
  title: string;
  icon: string;
  isNewTab?: boolean;
  selected?: boolean;
  subMenu?: ISubMenu[];
  isDisabled?: boolean;
}

export const NavMenuItem = ({
  link,
  title,
  icon,
  isNewTab = false,
  selected = false,
  subMenu,
  isDisabled = false
}: Props) => {
  const subMenuRef = useRef<any>(null);
  const linkRef = useRef<any>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        subMenuRef?.current &&
        !subMenuRef.current?.contains(event?.target) &&
        linkRef?.current &&
        !linkRef.current?.contains(event?.target)
      ) {
        setShowMore(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [subMenuRef]);

  const handleClick = (e: React.MouseEvent) => {
    if (isDisabled) {
      e.preventDefault();
      setShowMore(!showMore);
    }
  };

  return (
    <>
      <a
        className={`nav-menu-item ${selected ? 'nav-menu-item-selected' : ''}`}
        href={link}
        ref={linkRef}
        target={isNewTab ? '_blank' : '_self'}
        rel="noreferrer"
        onClick={handleClick}
      >
        <i className={icon} />
        <span>{title}</span>
      </a>

      {subMenu && (
        <div className="nav-submenu-wrapper" ref={subMenuRef}>
          <i
            className={`ri-arrow-down-s-line ${showMore && 'i-rotated'}`}
            onClick={() => setShowMore(!showMore)}
          />

          {showMore && (
            <div className="nav-submenu">
              {subMenu?.map((menuItem) => (
                <a href={menuItem.subNavItemPath}>{menuItem.subNavItemName}</a>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
