/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { CSSProperties, useState } from 'react';

interface Props {
  style?: CSSProperties;
}

export const Logo = ({ style }: Props) => {
  const [logoImg, setLogo] = useState(
    `${process.env.PUBLIC_URL}/logo_${process.env.REACT_APP_INSTANCE_ID}.svg`
  );

  return (
    <img
      style={style}
      alt="KF"
      src={logoImg}
      onError={() => {
        setLogo(
          `${process.env.PUBLIC_URL}/logo_${process.env.REACT_APP_INSTANCE_ID}.svg`
        );
      }}
    />
  );
};
