import React from 'react';
import { Button, Typography } from 'components';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppSelector } from 'store';
import './index.scss';

export const Header = () => {
  // const navigate = useNavigate();
  const today = new Date().getHours();

  let greeting;

  if (today < 12) {
    greeting = 'God morgen';
  } else if (today < 18) {
    greeting = 'God ettermiddag';
  } else {
    greeting = 'God kveld';
  }

  const navigateToSurveyTemplates = () => {
    window.location.href = `${process.env.REACT_APP_SURVEY_BASE_URL}templates/`;
  };

  const displayName = useAppSelector((state: RootState) => {
    return state.auth.displayName;
  });

  const authenticationDetails = useAppSelector((state: RootState) => {
    return state.auth.userAuthenticationSettings;
  });

  return (
    <div className="header-container">
      <Typography
        variant="h2"
        color="gray-900"
        style={{ margin: 0, padding: 0 }}
      >
        <span>{greeting},</span> {displayName}
      </Typography>

      {!authenticationDetails.isEnableReportUser && (
        <div className="header-button">
          <Button
            onClick={navigateToSurveyTemplates}
            label="Ny undersøkelse"
            disabled={!authenticationDetails.isEnableAddSurveyTemplate}
            type="primary"
            buttonClassNames="guided-tour-4-header"
            icon="ri-add-fill"
          />
        </div>
      )}
    </div>
  );
};
