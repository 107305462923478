import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Divider, Input, Logo, Typography } from 'components';
import { RootState, persistor, useAppDispatch, useAppSelector } from 'store';
import {
  login,
  loginFromBK,
  changeLoginAsUser,
  getActiveNotifications,
  setIsNotificationData
} from 'store/features/Auth';
import { NotificationData, ResponseValidation } from 'models/models';
import { responseStatus } from 'models/enum';
import { addResponseData } from 'store/responseDataHandle';
import './index.scss';

const LoginPageImage: string =
  require('assets/images/login-page-img.svg').default;

const formInitialValues = {
  email: '',
  password: ''
};

export const Login = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthenticated, loading } = useAppSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, []);

  // const queryParameter = useMemo(() => new URLSearchParams(search), [search]);
  const queryParameterOriginal = useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const queryParameter = new URLSearchParams();
  queryParameterOriginal.forEach((keyVal, name) => {
    queryParameter.append(name.toLowerCase(), keyVal);
  });

  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const userToken = useAppSelector(({ auth }) => auth.token);

  // const keepalive = () => {
  //   // eslint-disable-next-line no-undef
  //   const BASE_URL = process.env.REACT_APP_BK_BASE_URL;
  //   try {
  //     fetch(`${BASE_URL}/keepalive.jsp?origin=mbk`, {
  //       method: 'GET',
  //       credentials: 'include'
  //     }).catch(() => {
  //       console.log('error');
  //     });
  //   } catch (error) {
  //     console.log('error');
  //   }
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   const KEEP_ALIVE_INTERVAL = process.env.REACT_APP_KEEP_ALIVE_INTERVAL
  //     ? // eslint-disable-next-line no-undef
  //       process.env.REACT_APP_KEEP_ALIVE_INTERVAL
  //     : '25';
  //   // eslint-disable-next-line no-undef

  //   if (KEEP_ALIVE_INTERVAL !== '0') {
  //     setInterval(() => {
  //       // console.log('keep alive in');
  //       keepalive();
  //     }, 1000 * 60 * parseInt(KEEP_ALIVE_INTERVAL, 10));
  //   }
  // }, []);

  const loginFromExternal = async () => {
    if (userToken && userToken !== '') {
      await persistor.purge();
      await dispatch(
        loginFromBK({
          userToken: queryParameter.get('usertoken'),
          sessionId: queryParameter.get('sessionid'),
          impId: !isEmpty(queryParameter.get('impid'))
            ? queryParameter.get('impid')
            : null
        })
      ).then((authItem: any) => {
        if (!isEmpty(queryParameter.get('impid'))) {
          changeLoginAsUser(true);
        } else {
          changeLoginAsUser(false);
        }
        if (authItem && authItem.payload.token !== '') {
          navigate('/');
        } else {
          const responseData: ResponseValidation = {
            id: uuidv4(),
            type: responseStatus.ERROR,
            responseMessage: 'Authentication Error',
            responseMessageList: []
          };
          dispatch(addResponseData(responseData));
        }
      });
    } else {
      await dispatch(
        loginFromBK({
          userToken: queryParameter.get('usertoken'),
          sessionId: queryParameter.get('sessionid'),
          impId: !isEmpty(queryParameter.get('impid'))
            ? queryParameter.get('impid')
            : null
        })
      ).then((authItem: any) => {
        if (!isEmpty(queryParameter.get('impid'))) {
          changeLoginAsUser(true);
        } else {
          changeLoginAsUser(false);
        }
        if (authItem && authItem.payload.token !== '') {
          navigate('/');
        } else {
          const responseData: ResponseValidation = {
            id: uuidv4(),
            type: responseStatus.ERROR,
            responseMessage: 'Authentication Error',
            responseMessageList: []
          };
          dispatch(addResponseData(responseData));
        }
      });
    }
  };

  useEffect(() => {
    if (
      !isEmpty(queryParameter.get('usertoken')) &&
      !isEmpty(queryParameter.get('sessionid'))
    ) {
      setIsExternal(true);
      loginFromExternal();
    }
  }, []);

  const validationSchemaData = Yup.object({
    email: Yup.string()
      .email('Epostadressen er ikke gyldig')
      .required('Du må fylle inn e-post'),
    password: Yup.string()
      .min(3, 'Passordet må være minst 8 tegn langt')
      .required('Du må fylle inn et passord')
  });

  const onSubmit = async (
    values: any,
    { setFieldError, validateField }: any
  ) => {
    const { email, password } = values;
    setIsLoginLoading(true);
    await dispatch(
      login({
        username: email,
        password
      })
    ).then((authItem: any) => {
      if (authItem.meta.requestStatus === 'fulfilled') {
        if (authItem && authItem.payload.token !== '') {
          const getResponse = {
            getNotificationData: (response: NotificationData) => {
              if (response.enabled) {
                dispatch(setIsNotificationData(true));
              }
            }
          };
          dispatch(getActiveNotifications(getResponse));
        }
        if (
          authItem &&
          authItem.payload.token !== '' &&
          !authItem.payload.isFirstTimeUser
        ) {
          setIsLoginLoading(false);
          navigate('/');
        } else if (authItem.payload?.isFirstTimeUser) {
          setIsLoginLoading(false);
          window.location.href = '/users/change-password';
        } else {
          setIsLoginLoading(false);
          const responseData: ResponseValidation = {
            id: uuidv4(),
            type: responseStatus.ERROR,
            responseMessage: 'Authentication Error - login',
            responseMessageList: []
          };
          dispatch(addResponseData(responseData));
        }
      } else {
        setIsLoginLoading(false);
        setFieldError('password', 'Ugyldig brukernavn/passord');
      }
    });
  };

  const handleAskForHelp = () => {
    window.open(process.env.REACT_APP_ASK_FOR_HELP_URL, '_blank');
  };

  const handleForgotPassoword = () => {
    window.location.href = `/users/forgot-password`;
  };

  return isExternal ? (
    <div />
  ) : (
    <div className="login-page-container">
      <div className="login-image-section">
        <div className="logo-container">
          <Logo style={{ width: 'auto', height: '40px' }} />
        </div>

        <div className="login-image-container ">
          <div className="login-image">
            <img src={LoginPageImage} alt="" />
          </div>
        </div>
      </div>
      <div className="login-form-section">
        <div className="login-form-section-wrapper">
          <div className="login-title">
            <Typography variant="h3" color="brand-midnight">
              Logg inn
            </Typography>
          </div>
          <div className="login-form-container">
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchemaData}
              validateOnChange
              onSubmit={onSubmit}
            >
              <Form>
                <div className="form-elements">
                  <Input
                    label="E-postadresse"
                    name="email"
                    type="text"
                    placeholder=""
                    required
                  />
                </div>

                <div className="form-elements">
                  <Input
                    label="Passord"
                    name="password"
                    type="password"
                    placeholder=""
                    required
                  />
                </div>
                {/* <CheckboxSimple name="rememberMe" label="Husk meg" /> */}
                <span onClick={handleForgotPassoword}>
                  <Typography
                    variant="smallLink"
                    color="brand-midnight"
                    customClassName="forgot-password-text"
                  >
                    Glemt passord?
                  </Typography>
                </span>

                <div className="actions">
                  <Button
                    size="lg"
                    label="Logg inn"
                    type="primary"
                    htmlType="submit"
                    // isLoading={loading === loadingStates.PENDING}
                    isLoading={isLoginLoading}
                  />
                </div>
              </Form>
            </Formik>
          </div>
          <div className="account-creation-container">
            <div className="account-creation-section">
              <Divider type="solid" />
              <div className="account-creation-title-wrapper">
                <Typography variant="smallMedium" color="brand-midnight">
                  Har du ikke en konto?
                </Typography>
                <div
                  className="ask-for-help-btn-wrapper"
                  onClick={handleAskForHelp}
                >
                  <i className="ri-question-line" />
                  <Typography variant="smallRegular" color="navy-blue">
                    <span>Be om hjelp</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
