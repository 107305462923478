import React, { CSSProperties } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import './index.scss';

interface Props {
  size?: number;
  margin?: string;
  type?: 'clip' | 'pulse';
}

const Loader = ({ type = 'clip', size, margin = 'auto' }: Props) => {
  const override: CSSProperties = {
    margin
  };

  if (type === 'pulse')
    return <PulseLoader cssOverride={override} color="#f5cea3" size={size} />;
  return <ClipLoader cssOverride={override} color="#f5cea3" size={size} />;
};

export { Loader };

Loader.defaultProps = {
  size: undefined,
  margin: undefined,
  type: 'clip'
};
