import React from 'react';
import { responseStatus } from 'models/enum';
import { RootState, useAppSelector } from 'store';
import { ResponseContent } from './ResponseContent';

export const ResponseHandleContent = () => {
  const currentResponseList = useAppSelector((state: RootState) => {
    return state.responseHandling.responseList;
  });

  return (
    <div>
      {currentResponseList.map((responseItem) =>
        ResponseContent({
          id: responseItem.id,
          type: responseItem.type,
          title: responseItem.title,
          description: responseItem.responseMessage
        })
      )}
    </div>
  );
};
