import React from 'react';
import {
  Carousel,
  CarouselItem,
  SurveyCard,
  SymbolsItem,
  Typography
} from 'components';
import { useNavigate } from 'react-router-dom';
import { SurveyByStatusElement, SurveyManipulationData } from 'models/models';
import moment from 'moment';
import 'moment/locale/nb';
import { setSurveyManipulationData } from 'store/features/Auth';
import { RootState, useAppDispatch, useAppSelector } from 'store';

interface IProps {
  data: SurveyByStatusElement[];
}

export const SurveyStatuses = ({ data }: IProps) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const surveyManipulationDataStore = useAppSelector((state: RootState) => {
    return state.auth.surveyManipulationData;
  });

  const navigateToSurveyModule = (item: SurveyByStatusElement) => {
    const surveyManipulationData: SurveyManipulationData = {
      ...surveyManipulationDataStore,
      surveyId: item.surveyId,
      formId: item.formId,
      surveyDataId: item.surveyDataId,
      surveyTemplateId: item.surveyTemplateId || '',
      orgUnitId: item.orgUnitId,
      surveyName: item.surveyName,
      surveyUniqueId: item.surveyUniqueId || null,
      surveyYear: parseInt(item.surveyYear, 10)
    };
    dispatch(setSurveyManipulationData(surveyManipulationData));
    window.location.href = `${process.env.REACT_APP_SURVEY_BASE_URL}quick-overview`;
  };

  const generateStatusDescription = (
    lastReminderDate: string | null,
    lastModifiedDate: string | null
  ): string => {
    const formatDate = (date: string) =>
      moment(date).locale('nb').format('DD. MMMM YYYY');

    if (lastReminderDate && lastModifiedDate) {
      return `Siste påminnelse sendt ${formatDate(lastReminderDate)}`;
    }
    if (lastReminderDate) {
      return `Siste påminnelse sendt ${formatDate(lastReminderDate)}`;
    }
    if (lastModifiedDate) {
      return `Sist endret ${formatDate(lastModifiedDate)}`;
    }

    return '';
  };

  return (
    <div className="survey-statuses-wrapper">
      <Carousel>
        {data.slice(0, 8).map((item) => (
          <CarouselItem key={item.surveyDataId}>
            <SurveyCard
              onClick={() => navigateToSurveyModule(item)}
              key={item.surveyDataId}
              title={item.surveyName}
              date={`${moment(item.startDate)
                .locale('nb')
                .format('DD. MMMM YYYY')} - ${moment(item.endDate)
                .locale('nb')
                .format('DD. MMMM YYYY')}`}
              status={item.status}
              statusDescription={generateStatusDescription(
                item.lastReminderDate,
                item.lastModifiedDate
              )}
              progress={
                item.answerPercentage > 100 ? 100 : item.answerPercentage
              }
            />
          </CarouselItem>
        ))}
      </Carousel>

      <div
        className="survey-card-container view-all-surveys"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_SURVEY_BASE_URL}`;
        }}
      >
        <Typography
          variant="h2"
          color="blue-700"
          style={{ margin: 0, padding: 0 }}
        >
          Se alle
        </Typography>
        <SymbolsItem icon="ri-arrow-right-line" />
      </div>
    </div>
  );
};
