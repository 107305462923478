import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadingStates } from 'models/enum';
import { YearDataItem } from 'models/models';
import agent from 'services/service';
import { date } from 'yup';

interface IReportConfigData {
  yearList: YearDataItem[];
  status: string;
  error: boolean;
}

export const getYearDataList = createAsyncThunk(
  'masterData/getYearLists',
  async (dataItem: any, thunkAPI) => {
    try {
      const data = await agent.MasterData.getYearList();
      dataItem.getYearListCallBack(data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const initialState: IReportConfigData = {
  yearList: [],
  status: '',
  error: false
};

const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getYearDataList
    builder.addCase(getYearDataList.pending, (state) => {
      state.status = loadingStates.LOADING;
      state.error = false;
    });
    builder.addCase(getYearDataList.fulfilled, (state, { payload }) => {
      state.yearList = payload.data.sort(
        (a: { year: number }, b: { year: number }) => b.year - a.year
      );
      state.status = loadingStates.SUCCESS;
      state.error = false;
    });
    builder.addCase(getYearDataList.rejected, (state) => {
      state.status = loadingStates.FAILURE;
      state.error = true;
    });
  }
});

const { actions, reducer } = masterDataSlice;
// export const {} = actions;
export default reducer;
