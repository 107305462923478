import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { loadingStates } from 'models/enum';
import agent from 'services/service';
import { setGuidedTour } from '../Auth';

export enum TourNames {
  SURVEY = 'Survey',
  USER = 'User',
  ORGUNIT = 'OrgUnit',
  HOME = 'Home'
}

export interface ITourItem {
  moduleName: TourNames;
  currentStep: number;
  isTourCompleted: boolean;
  isTourStarted: boolean;
  isTourSkipped: boolean;
}

interface IInitialState {
  moduleName: string;
  showGuidedTourModal: boolean;
  isRun: boolean;
  tour: ITourItem[];
  getStatus: string;
  getError: boolean;
  postStatus: string;
  postError: boolean;
}

export const getUserGuidedTourData = createAsyncThunk(
  'home/getGuidedTours',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await agent.GuidedTour.getUserGuidedTour();

      if (response.code === 200) {
        const data = response.data as ITourItem[];
        return data;
      }

      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const updateUserGuidedTourData = createAsyncThunk(
  'home/updateGuidedTours',
  async (tourData: ITourItem, thunkAPI) => {
    try {
      const response = await agent.GuidedTour.updateUserGuidedTour(tourData!);
      if (response.code === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const initialState: IInitialState = {
  moduleName: TourNames.HOME,
  showGuidedTourModal: false,
  isRun: false,
  tour: [],
  getStatus: loadingStates.IDLE,
  getError: false,
  postStatus: loadingStates.IDLE,
  postError: false
};

const tourSlice = createSlice({
  name: 'guidedTour',
  initialState,
  reducers: {
    openTour(state) {
      state.isRun = true;
    },
    closeTour(state) {
      state.isRun = false;
    },
    nextStep(state) {
      const tourItem = state.tour.find(
        (item) => item.moduleName === state.moduleName
      );
      if (tourItem) {
        tourItem.currentStep += 1;
      }
    },
    prevStep(state) {
      const tourItem = state.tour.find(
        (item) => item.moduleName === state.moduleName
      );
      if (tourItem) {
        tourItem.currentStep -= 1;
      }
    },
    resetTour(state) {
      const tourItem = state.tour.find(
        (item) => item.moduleName === state.moduleName
      );
      if (tourItem) {
        tourItem.currentStep = 0;
      }
    },
    updateTourItem: (state, { payload }) => {
      const tourItem = state.tour.find(
        (item) => item.moduleName === state.moduleName
      );

      if (tourItem) {
        Object.assign(tourItem, payload);
      }
    },
    setShowGuidedTourModal(state, { payload }) {
      state.showGuidedTourModal = payload;
    }
  },
  extraReducers: (builder) => {
    // Get
    builder
      .addCase(getUserGuidedTourData.pending, (state) => {
        state.getStatus = loadingStates.LOADING;
        state.getError = false;
      })
      .addCase(getUserGuidedTourData.fulfilled, (state, { payload }) => {
        if (!isEmpty(payload)) {
          state.tour = payload;
        } else {
          const initialTour = {
            moduleName: TourNames.HOME,
            currentStep: 0,
            isTourCompleted: false,
            isTourStarted: false,
            isTourSkipped: false
          };
          state.tour.push(initialTour);
        }
        state.getStatus = loadingStates.SUCCESS;
        state.getError = false;
      })
      .addCase(getUserGuidedTourData.rejected, (state) => {
        state.getStatus = loadingStates.FAILURE;
        state.getError = true;
      });

    // Update
    builder
      .addCase(updateUserGuidedTourData.pending, (state) => {
        state.postStatus = loadingStates.LOADING;
        state.postError = false;
      })
      .addCase(updateUserGuidedTourData.fulfilled, (state, { payload }) => {
        state.postStatus = loadingStates.SUCCESS;
        state.postError = false;
      })
      .addCase(updateUserGuidedTourData.rejected, (state) => {
        state.postStatus = loadingStates.FAILURE;
        state.postError = true;
      });
  }
});

const { actions, reducer } = tourSlice;
export const {
  openTour,
  closeTour,
  nextStep,
  prevStep,
  resetTour,
  setShowGuidedTourModal,
  updateTourItem
} = actions;
export default reducer;
