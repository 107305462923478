import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Dimmer } from '../Dimmer';
import './index.scss';

interface PropsModalBody {
  children?: React.ReactNode;
}

export const ModalBody = ({ children }: PropsModalBody) => (
  <div className="modal-body">{children}</div>
);
interface PropsModalFooter {
  children?: React.ReactNode;
}

export const ModalFooter = ({ children }: PropsModalFooter) => (
  <div className="modal-footer">{children}</div>
);

interface Props {
  title: string;
  onClose: () => void;
  show: boolean;
  size?: 'small' | 'medium' | 'large' | 'full';
  children?: React.ReactNode;
  customClasses?: string;
  style?: CSSProperties;
  allowCloseButton?: boolean;
}

export const Modal = ({
  title,
  onClose,
  show,
  size,
  children,
  customClasses,
  style,
  allowCloseButton = true
}: Props) => {
  return (
    <div>
      {show && (
        <>
          <Dimmer />
          <div
            className={classNames(`modal-${size}`, {
              [`${customClasses}-${size}`]: customClasses
            })}
            style={style}
          >
            <div className="modal-header">
              <h5>{title}</h5>
              {allowCloseButton && (
                <i
                  className="ri-close-line modal-close-icon"
                  onClick={() => {
                    onClose();
                  }}
                  role="presentation"
                />
              )}
            </div>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

Modal.defaultProps = {
  size: 'medium',
  customClasses: undefined,
  children: undefined
};

ModalBody.defaultProps = {
  children: undefined
};

ModalFooter.defaultProps = {
  children: undefined
};
