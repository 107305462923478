import React from 'react';
import { toast } from 'react-toastify';
import './index.scss';

interface Props {
  title?: string;
  description?: string;
  icon?: boolean;
  type?: 'info' | 'success' | 'warning' | 'error';
  close?: boolean;
}

export const Alert = ({
  title,
  description,
  type = 'info',
  close,
  icon
}: Props) => {
  const getIcon = (type: string) => {
    switch (type) {
      case 'info':
        return 'ri-information-fill';
      case 'success':
        return 'ri-checkbox-circle-fill';
      case 'warning':
        return 'ri-alert-fill';
      case 'error':
        return 'ri-close-circle-fill';
      default:
        return 'ri-information-fill';
    }
  };

  const CloseButton = ({ closeToast }: any) => (
    <i
      className={`ri-close-fill toast-close-icon toast-close-${type}`}
      onClick={closeToast}
      role="presentation"
    />
  );

  return toast(
    <div className="alert-container">
      {icon && (
        <div className="alert-icon-container">
          <i className={`alert-icon-${type} ${getIcon(type)}`} />
        </div>
      )}
      <div>
        <div className={`alert-title-${type}`}>{title}</div>
        {description && (
          <div className={`alert-description-${type}`}>{description}</div>
        )}
      </div>
    </div>,
    {
      className: `alert-main-container-${type}`,
      position: 'bottom-left',
      closeButton: close ? CloseButton : false,
      autoClose: 5000,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: true
    }
  );
};

Alert.defaultProps = {
  title: undefined,
  description: undefined,
  icon: undefined,
  type: 'info',
  close: undefined
};
