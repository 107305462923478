import React from 'react';

interface Props {
  width: string;
  height: string;
}

export const AvatarIcon = ({ width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 18.2321C23.9706 18.2321 28 14.2026 28 9.23206C28 4.26149 23.9706 0.232056 19 0.232056C14.0294 0.232056 10 4.26149 10 9.23206C10 14.2026 14.0294 18.2321 19 18.2321ZM19 42.2321C26.5623 42.2321 33.3193 38.7157 37.7217 33.2323C33.3193 27.7486 26.5622 24.2321 18.9996 24.2321C11.4373 24.2321 4.68029 27.7484 0.277924 33.2318C4.68028 38.7155 11.4374 42.2321 19 42.2321Z"
      />
    </svg>
  );
};
