import { request } from 'services/api';
import { ITourItem } from 'store/features/GuidedTour';

const GuidedTour = {
  getUserGuidedTour: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/home/get-user-guided-tour`
    ),
  updateUserGuidedTour: (tourData: ITourItem) =>
    request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/home/update-user-guided-tour`,
      JSON.stringify(tourData)
    )
};

export default GuidedTour;
