import { createSlice } from '@reduxjs/toolkit';
import { ResponseValidation } from 'models/models';

export interface IProps {
  responseList: ResponseValidation[];
}

const initialState: IProps = {
  responseList: []
};

const responseHandlingSlice = createSlice({
  name: 'responseHandling',
  initialState,
  reducers: {
    addResponseData: (state, { payload }) => {
      state.responseList.push(payload);
    },
    clearResponseData: (state, { payload }) => {
      const arrayResponse = state.responseList.filter(
        (response) => response.id !== payload
      );
      state.responseList = arrayResponse;
    }
  }
});

const { actions, reducer } = responseHandlingSlice;
export const { addResponseData, clearResponseData } = actions;
export default reducer;
