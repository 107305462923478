import React from 'react';
import './index.scss';

interface Props {
  color?:
    | 'green'
    | 'gray'
    | 'red'
    | 'black'
    | 'blue'
    | 'white'
    | null
    | undefined;
  size?: 'small' | 'medium';
  icon: string;
  onClick?: () => void;
  customClass?: string;
}
export const SymbolsItem = ({
  color,
  size = 'medium',
  icon,
  onClick,
  customClass = ''
}: Props) => {
  return (
    <div className={`symbols-content-div ${customClass}`}>
      <i
        className={`${icon} symbols-${color} symbols-size-${size}`}
        onClick={onClick}
      />
    </div>
  );
};
