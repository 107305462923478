import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ReactPortal,
  Typography
} from 'components';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  TourNames,
  openTour,
  updateTourItem,
  updateUserGuidedTourData
} from 'store/features/GuidedTour';
import './index.scss';
import { setGuidedTour } from 'store/features/Auth';

const GuidedTourImage: string =
  require('assets/images/guided-tour-bg.svg').default;

interface IProps {
  showGuidedTour: boolean;
  closeGuidedTour: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GuidedTourModal = ({
  showGuidedTour,
  closeGuidedTour
}: IProps) => {
  const dispatch = useAppDispatch();

  const tourData = useAppSelector((state: RootState) =>
    state.guidedTour.tour.find((tour) => tour.moduleName === TourNames.HOME)
  );

  const handleColseModal = () => {
    dispatch(
      updateTourItem({
        currentStep: 0,
        isTourCompleted: false,
        isTourStarted: false,
        isTourSkipped: true
      })
    );
    closeGuidedTour(false);
    dispatch(setGuidedTour({ isFirstTimeUser: false, isTourSkipped: true }));
  };

  return (
    <ReactPortal wrapperId="guided-tour-modal">
      <Modal
        title="Velkommen. Vi håper du finner deg til rette."
        size="large"
        show={showGuidedTour}
        onClose={handleColseModal}
        allowCloseButton={false}
      >
        <ModalBody>
          <img src={GuidedTourImage} alt="" />
          <Typography
            variant="h5"
            style={{
              color: '#000000',
              textAlign: 'center',
              marginBottom: '6px'
            }}
          >
            Få en rask omvisning av de viktigste delene av Bedrekommune og se
            mulighetene som <br /> åpner seg.
          </Typography>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Utforsk selv"
            type="white"
            onClick={handleColseModal}
          />
          <Button
            label="Få omvisning"
            type="primary"
            onClick={() => {
              closeGuidedTour(false);
              dispatch(openTour());
              dispatch(
                updateTourItem({
                  currentStep: 0,
                  isTourStarted: true,
                  isTourSkipped: false,
                  isTourCompleted: false
                })
              );
              dispatch(
                updateUserGuidedTourData({
                  ...tourData!,
                  currentStep: 0,
                  isTourStarted: true,
                  isTourSkipped: false,
                  isTourCompleted: false
                })
              );
            }}
          />
        </ModalFooter>
      </Modal>
    </ReactPortal>
  );
};
