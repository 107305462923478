import React from 'react';
import { SummariesSkeleton, SummaryCard } from 'components';
import { RootState, useAppSelector } from 'store';
import { SummaryCardTypes, loadingStates } from 'models/enum';

export const SurveySummaries = () => {
  const {
    totalActiveSurveysCount,
    totalAnswersReceivedCount,
    totalPendingAnswersCount,
    responseRateTotalAverage,
    activeSurveyDetails
  } = useAppSelector((state: RootState) => {
    return state.home.activeSurveys.activeSurveyDetails;
  });

  const activeSurveyDetailsStatus = useAppSelector((state: RootState) => {
    return state.home.activeSurveys.activeSurveyDetailsStatus;
  });

  return !activeSurveyDetails.length &&
    activeSurveyDetailsStatus === loadingStates.LOADING ? (
    <SummariesSkeleton />
  ) : (
    <div className="summary-cards-wrapper">
      <span className="guided-tour-1-summary" />
      <SummaryCard
        color="blue"
        icon="ri-survey-line"
        title="Aktive undersøkelser"
        amount={totalActiveSurveysCount}
        cardType={SummaryCardTypes.ACTIVE_INVESTIGATIONS}
      />

      <SummaryCard
        color="green"
        icon="ri-task-line"
        title="Antall svar"
        amount={totalAnswersReceivedCount}
        cardType={SummaryCardTypes.NUMBER_OF_RESPONSES}
      />
      <SummaryCard
        color="yellow"
        icon="ri-timer-2-line"
        title="Avventer svar"
        amount={totalPendingAnswersCount}
        cardType={SummaryCardTypes.WAITING_FOR_ANSWER}
      />
      <SummaryCard
        color="beige"
        icon="ri-line-chart-fill"
        title="Svarprosent gjennomsnitt"
        amount={responseRateTotalAverage}
        cardType={SummaryCardTypes.RESPONSE_RATE_AVERAGE}
      />
    </div>
  );
};
