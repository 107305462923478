import React from 'react';
import { Dimmer } from '..';
import './index.scss';

interface Props {
  fill: number;
  size?: 'small' | 'default' | 'large';
  status?: string;
}

export const ProgressBar = ({ fill, size = 'default', status }: Props) => {
  const getColorsByState = (status: string) => {
    switch (status) {
      case 'Aktiv':
        return 'blue-bar';
      case 'Inaktiv':
        return 'grey-bar';
      case 'Fullført':
        return 'green-bar';
      case 'Nylig lagt til':
        return;
      case 'Slettet':
        return 'red-bar';
      case 'Planlagt':
      case 'Yellow':
        return 'yellow-bar';
      default:
        return 'blue-bar';
    }
  };

  return (
    <div className="progress-bar-container">
      <div
        style={{ width: `${fill}%` }}
        className={`progress-bar-${size} ${
          status ? getColorsByState(status) : ''
        }`}
      />
    </div>
  );
};

interface ProgressSpinnerProps {
  size?: 'default' | 'large';
  color?: 'green' | 'gray';
}

export const ProgressSpinner = ({
  size = 'default',
  color
}: ProgressSpinnerProps) => {
  return (
    <>
      <Dimmer />
      <div
        className={`progress-spinner-${size} progress-spinner-color-${color}`}
      />
    </>
  );
};

interface ProgressSpinnerElementProps {
  size?: 'default' | 'large' | 'small';
  color?: 'green' | 'gray';
}

export const ProgressSpinnerElement = ({
  size = 'default',
  color
}: ProgressSpinnerElementProps) => {
  return (
    <div
      className={`progress-spinner-${size} progress-spinner-color-${color}`}
    />
  );
};

ProgressBar.defaultProps = {
  size: 'default'
};

ProgressSpinner.defaultProps = {
  size: 'default',
  color: 'green'
};

ProgressSpinnerElement.defaultProps = {
  size: 'default',
  color: 'green'
};
