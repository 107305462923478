import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import './index.scss';

type Record = {
  [key: string]: any;
};

type Option = Record;
interface Props {
  id?: string;
  label?: string;
  name: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (option: Option) => void;
  checked?: boolean;
  labelClassNames?: string;
  inputLabelClassNames?: string;
}

export const Radio = ({
  id,
  name,
  label,
  description,
  required,
  disabled
}: Props) => {
  const [field, meta] = useField(name);

  return (
    <>
      <label className="radio-container" htmlFor={id || name}>
        {label && (
          <span className="input-lable">
            {label} {required && <span>*</span>}
          </span>
        )}
        {description && <p className="description">{description}</p>}
        <input id={id || name} type="radio" disabled={disabled} {...field} />
        <span className="radio-checkmark" />
      </label>
      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};

export const RadioSimple = ({
  id,
  name,
  label,
  description,
  required,
  disabled,
  onChange,
  checked,
  labelClassNames,
  inputLabelClassNames
}: Props) => {
  return (
    <label
      className={classNames(
        `radio-container${disabled ? `--disabled` : ''}`,
        labelClassNames
      )}
      htmlFor={id || name}
    >
      {label && (
        <span className={classNames('input-label-radio', inputLabelClassNames)}>
          {label} {required && <span>*</span>}
        </span>
      )}
      {description && <p className="description">{description}</p>}
      <input
        id={id || name}
        type="radio"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <span className="radio-checkmark" />
    </label>
  );
};

Radio.defaultProps = {
  id: undefined,
  label: undefined,
  description: undefined,
  required: undefined,
  disabled: undefined,
  onChange: undefined,
  checked: undefined,
  labelClassNames: undefined,
  inputLabelClassNames: undefined
};
