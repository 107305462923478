export enum roleTypes {
  KF_ADMIN = 'KFAdministrator',
  ADMIN = 'Administrator',
  REPORT_USER = 'ReportUser',
  ADMIN_EM_SURVEY = 'AdministratorEmployeeSurvey',
  REPORT_USER_EM_SURVEY = 'ReportUserEmployeeSurvey',
  RAW_DATA_FILE_ACCESS = 'RawDataFilesAccess',
  FORM_BUILDER = 'FormBuilder',
  RESPONDENT_REPORT_USER = 'RespondentAndReportUser'
}

export enum loadingStates {
  IDLE = 'idle',
  PENDING = 'pending',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure'
}

export enum pagination {
  PAGE_SIZE = 10
}

export enum responseStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export enum optionDropDownTypes {
  Show_national_average = 'Show national average',
  Show_average_of_owning_org_unit = 'Show average of owning org.unit',
  Show_my_muncipality_average = 'Show my muncipality average'
}

export enum CalendarViewTypes {
  WEEK = 'Week',
  DAY = 'Day'
}

export enum EventStatus {
  ACTIVE = 'Aktiv',
  PLANNED = 'Planlagt',
  FINISHED = 'Fullført'
}

export enum SummaryCardTypes {
  ACTIVE_INVESTIGATIONS = 1,
  NUMBER_OF_RESPONSES = 2,
  WAITING_FOR_ANSWER = 3,
  RESPONSE_RATE_AVERAGE = 4
}
