import React from 'react';
import './index.scss';

interface Props {
  icon?: string;
  variant: 'info' | 'success' | 'warning' | 'error';
  children: React.ReactNode | string | number;
  styles?: React.CSSProperties;
  close?: boolean;
  setClose?: (value: boolean) => void;
}

export const AlertMessage = ({
  icon,
  variant,
  children,
  styles,
  close,
  setClose
}: Props) => {
  return !close ? (
    <div
      className={`alert-message-container variant-${variant}`}
      style={styles}
    >
      <span>
        {icon && (
          <div className="alert-message-icon-container">
            <i className={icon} />
          </div>
        )}
        <div className="alert-message-content-container">{children}</div>
      </span>

      {!close && setClose && (
        <div
          className="am-close-btn"
          role="presentation"
          onClick={() => setClose && setClose(true)}
        >
          <i className="ri-close-fill" />
        </div>
      )}
    </div>
  ) : null;
};

AlertMessage.defaultProps = {
  icon: '',
  styles: undefined,
  close: false,
  setClose: undefined
};
