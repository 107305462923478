import React, { useEffect, useState } from 'react';
import {
  HomeView,
  LoginAsUserContent,
  CookieConsentBanner,
  NotificationContent
} from 'components';
import { useAppDispatch } from 'store';
import { getUserAuthenticationSettings } from 'store/features/Auth';
import { readCookieConsent } from 'helpers';

export const HomePage = () => {
  const [cookieConsent, setCookieConsent] = useState(readCookieConsent());
  const dispatch = useAppDispatch();

  const LoadInitialData = async () => {
    await dispatch(getUserAuthenticationSettings());
  };

  const handleMatomoStatistics = () => {
    // @ts-ignore
    // eslint-disable-next-line
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    // eslint-disable-next-line
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = `${process.env.REACT_APP_MATOMOLINK}`;
    // @ts-ignore
    s.parentNode.insertBefore(g, s);
  };

  useEffect(() => {
    if (!cookieConsent.isCookieConsent && cookieConsent.isAcceptCookies) {
      handleMatomoStatistics();
    }
  }, [cookieConsent.isAcceptCookies]);

  useEffect(() => {
    LoadInitialData();
  }, []);

  return (
    <>
      <LoginAsUserContent />
      <NotificationContent />
      <HomeView />
      {cookieConsent.isCookieConsent && (
        <CookieConsentBanner setCookieConsent={setCookieConsent} />
      )}
    </>
  );
};
