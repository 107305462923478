import React, { PropsWithChildren } from 'react';

export const InlineWrapperWithMargin = ({
  children
}: PropsWithChildren<unknown>) => {
  return <span style={{ width: '25%' }}>{children}</span>;
};

export const InlineWrapperSurveys = ({
  children
}: PropsWithChildren<unknown>) => {
  return <span style={{ width: '33.3%' }}>{children}</span>;
};

export const InlineWrapperTabs = ({ children }: PropsWithChildren<unknown>) => {
  return <span style={{ marginRight: '16px' }}>{children}</span>;
};

export const InlineWrapperStatuses = ({
  children
}: PropsWithChildren<unknown>) => {
  return <span style={{ marginLeft: '3px' }}>{children}</span>;
};

export const InlineWrapperCalendar = ({
  children
}: PropsWithChildren<unknown>) => {
  return <span style={{ width: 'calc(100% - 200px)' }}>{children}</span>;
};
