/* eslint-disable react/no-unstable-nested-components */
import React, {
  useState,
  ReactElement,
  Fragment,
  useEffect,
  useRef
} from 'react';
import { Popover, ArrowContainer, PopoverPosition } from 'react-tiny-popover';
import './index.scss';

interface PropsPopoverHeader {
  children?: React.ReactNode;
}

export const PopoverHeader = ({ children }: PropsPopoverHeader) => (
  <div className="popover-header">{children}</div>
);

interface PropsPopoverBody {
  children?: React.ReactNode;
  onScroll?: (e: any) => void;
}

export const PopoverBody = ({ children, onScroll }: PropsPopoverBody) => (
  <div className="popover-body" onScroll={onScroll}>
    {children}
  </div>
);
interface PropsPopoverFooter {
  children?: React.ReactNode;
}

export const PopoverFooter = ({ children }: PropsPopoverFooter) => (
  <div className="popover-footer">{children}</div>
);

interface Props {
  children: any;
  customClass?: string;
  buttonContent: string | ReactElement;
  position?: PopoverPosition[];
}

export const PopoverComponent = ({
  children,
  customClass,
  buttonContent,
  position
}: Props) => {
  const popoverRef = useRef<any>(null);
  const popoverMainRef = useRef<any>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        popoverRef?.current &&
        !popoverRef.current?.contains(event?.target) &&
        !popoverMainRef.current?.contains(event?.target)
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverRef]);

  return (
    <Popover
      ref={popoverMainRef}
      isOpen={isPopoverOpen}
      positions={position || ['bottom']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div ref={popoverRef}>{children}</div>
        </ArrowContainer>
      )}
      align="center"
    >
      <button
        type="button"
        className={`"popover-close" ${customClass}`}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        {buttonContent}
      </button>
    </Popover>
  );
};

PopoverHeader.defaultProps = {
  children: null
};

PopoverBody.defaultProps = {
  children: null
};

PopoverFooter.defaultProps = {
  children: null
};
