import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from 'store/features/Auth';
import responseHandlingReducer from 'store/responseDataHandle';
import tourSlice from 'store/features/GuidedTour';
import homeSlice from 'store/features/Home';
import masterDataSlice from 'store/masterData';

const persistConfig = {
  key: '_Authentication',
  keyPrefix: 'MBK',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, authSlice);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    responseHandling: responseHandlingReducer,
    guidedTour: tourSlice,
    home: homeSlice,
    masterData: masterDataSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
