import React from 'react';
import { Badge, ProgressBar, Typography } from 'components';
import './index.scss';

interface ISurveyCard {
  title: string;
  date: string;
  status: string;
  statusDescription: string;
  progress: number;
  onClick?: () => void;
}

export const SurveyCard = ({
  title,
  date,
  status,
  statusDescription,
  progress,
  onClick
}: ISurveyCard) => {
  return (
    <div className="survey-card-container" onClick={onClick}>
      <div className="survey-card-header">
        <Typography
          variant="h5"
          color="brand-midnight"
          style={{ margin: 0, padding: 0 }}
        >
          {title}
        </Typography>

        <Badge status={status} text={status} rounded dot />
      </div>

      <Typography
        variant="baseRegular"
        color="gray-900"
        style={{ margin: 0, padding: 0 }}
      >
        {date}
      </Typography>

      <Typography
        variant="smallMedium"
        color="gray-500"
        style={{ marginTop: 40, padding: 0, minHeight: 20 }}
      >
        {statusDescription}
      </Typography>

      <div className="survey-progress">
        <ProgressBar fill={progress} size="small" status={status} />
        <Typography variant="baseMedium" color="gray-400">
          {`${progress}%`}
        </Typography>
      </div>
    </div>
  );
};
