import React from 'react';
import classNames from 'classnames';

import './index.scss';

interface BreadcrumbItemProps {
  // eslint-disable-next-line
  id: number | string;
  label: string;
  icon?: string;
  selected?: boolean;
}

export const BreadcrumbItem = ({
  label,
  icon,
  selected
}: BreadcrumbItemProps) => {
  return (
    <div
      className={`breadcrumb-item ${selected ? 'breadcrumb-item-active' : ''}`}
    >
      <i className="ri-arrow-right-s-line" />
      {icon && <i className={`icon ${icon}`} />}
      <span>{label}</span>
    </div>
  );
};

interface Props {
  items: BreadcrumbItemProps[];
}

export const Breadcrumb = ({ items }: Props) => {
  return items?.length ? (
    <div className="breadcrumb">
      {items?.length > 4 && (
        <div className="breadcrumb-item more">
          <i className="ri-more-fill" />
        </div>
      )}
      {items?.slice(-1 * 4)?.map((item) => {
        return (
          <BreadcrumbItem
            key={item.id}
            id={item.id}
            label={item.label}
            icon={item.icon}
            selected={item.selected}
          />
        );
      })}
    </div>
  ) : null;
};

interface SimpleBreadcrumbProps {
  crumbs: string[];
  selected?: (value: string) => void;
}

export const SimpleBreadcrumb = ({
  crumbs,
  selected
}: SimpleBreadcrumbProps) => {
  const isLast = (index: number) => {
    return index === crumbs.length - 1;
  };

  return (
    <nav className="simple-breadcrumb-wrapper">
      <ol className="simple-breadcrumb">
        {crumbs?.map((crumb, ci) => {
          const activeItem = isLast(ci) ? 'simple-breadcrumb-active' : '';

          return (
            <li key={Math.random() * 100} className="simple-breadcrumb-item">
              <span
                className={activeItem}
                onClick={() => {
                  if (selected) selected(crumb);
                }}
                role="presentation"
              >
                {crumb}
                {!isLast(ci) ? (
                  <i
                    className={classNames(
                      `ri-arrow-right-s-line ${
                        crumbs.length - 2 === ci
                          ? 'simple-breadcrumb-active'
                          : ''
                      }`
                    )}
                  />
                ) : null}
              </span>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

BreadcrumbItem.defaultProps = {
  icon: undefined,
  selected: undefined
};

SimpleBreadcrumb.defaultProps = {
  selected: undefined
};
