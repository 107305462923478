import classNames from 'classnames';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef
} from 'react';
import { ResizableBox } from 'react-resizable';
import { Button } from '../Button';
import { ProgressSpinnerElement } from '../ProgressBar';
import './index.scss';

interface Props {
  label?: string;
  selectedText?: string;
  dropDownElement: 'default' | 'element';
  element?: ReactElement;
  children?: React.ReactNode;
  onSearch?: (search: string) => void;
  hideBack?: boolean;
  handleBack?: Function;
  onCancel?: () => void;
  onApply?: () => void;
  hideFooter?: boolean;
  selectMenu?: boolean;
  // closeDropDown?: boolean;
  multiSelect?: boolean;
  showDropDown: boolean;
  disabled?: boolean;
  setShowDropDown: Function;
  dropDownComponent?: string;
  resizeBoxStyles?: string;
  dropdownClasses?: string;
  dropdownPanelClasses?: string;
  isResize?: boolean;
  currentSearchTerm?: string;
  primaryOrgUnitView?: boolean;
  setSelectedOrgUnit?: Function;
  setOrgUnitDropDownState?: Dispatch<SetStateAction<boolean>>;
  userPrimaryOrgUnitsState?: any;
  isLoading?: boolean;
}

export const Dropdown = ({
  label,
  selectedText,
  dropDownElement = 'default',
  element,
  children,
  onSearch,
  hideBack = false,
  handleBack,
  onCancel,
  onApply,
  hideFooter = false,
  selectMenu,
  multiSelect = false,
  dropDownComponent = 'default',
  showDropDown,
  setShowDropDown,
  resizeBoxStyles,
  disabled,
  dropdownClasses,
  dropdownPanelClasses,
  isResize = false,
  currentSearchTerm = '',
  primaryOrgUnitView = false,
  setSelectedOrgUnit,
  setOrgUnitDropDownState,
  userPrimaryOrgUnitsState,
  isLoading = false
}: Props) => {
  const dropDownRef = useRef<any>(null);
  const treeViewSelectRef = useRef<any>(null);
  // const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        !multiSelect &&
        dropDownRef?.current &&
        !treeViewSelectRef.current?.contains(event?.target) &&
        !dropDownRef.current?.contains(event?.target)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef]);

  /*   useEffect(() => {
    if (disabled && treeViewSelectRef?.current) {
      treeViewSelectRef?.current.
    }
  }, [disabled, treeViewSelectRef]);
 */
  return (
    <>
      {label && <span className="dropdown-select-label">{label}</span>}
      <button
        type="button"
        id={`${selectMenu ? 'select-menu' : ''}`}
        className={classNames(
          `${
            dropDownElement === 'default'
              ? 'dropdown-select'
              : classNames('dropdown-select-with-element', {
                  __disabled: disabled
                })
          }`,
          disabled && 'dropdown-select-disabled',
          isLoading && 'loading-dropdown-select',
          showDropDown ? 'dropdown-select-focused' : '',
          dropdownClasses
        )}
        disabled={disabled}
        ref={treeViewSelectRef}
        onClick={() => {
          if (disabled) {
            setShowDropDown(false);
          } else {
            setShowDropDown(!showDropDown);
          }
        }}
      >
        <span>{dropDownElement === 'default' ? selectedText : element}</span>

        {isLoading && <ProgressSpinnerElement color="gray" size="small" />}
      </button>
      {isResize && showDropDown ? (
        <ResizableBox
          className={classNames('box', resizeBoxStyles)}
          width={320}
          height={220}
          minConstraints={[320, 220]}
          maxConstraints={[500, 400]}
        >
          {showDropDown && (
            <div
              id={`${selectMenu ? 'select-menu' : ''}`}
              className={classNames(
                // eslint-disable-next-line no-nested-ternary
                dropDownElement === 'default' && isResize
                  ? `dropdown-select-drop-down-resize`
                  : dropDownComponent === 'default'
                  ? `dropdown-select-drop-down-element`
                  : `userTable-dropdown`,
                dropdownPanelClasses
              )}
              // ref={dropDownRef}
            >
              {!hideBack && (
                <div className="back-to-filters">
                  <i className="ri-arrow-left-s-line" />
                  <span
                    onClick={() => {
                      if (handleBack) {
                        handleBack();
                      }
                      // setSearchText('');
                      if (onSearch) {
                        onSearch('');
                      }
                      // setShowDropDown(false);
                    }}
                  >
                    Tilbake til primær
                  </span>
                </div>
              )}

              {/* {primaryOrgUnitView &&
                setSelectedOrgUnit &&
                setOrgUnitDropDownState && (
                  <PrimaryOrgUnitDropDown
                    setSelectedOrgUnit={setSelectedOrgUnit}
                    userPrimaryOrgUnitsState={userPrimaryOrgUnitsState}
                  />
                )} */}

              {onSearch && (
                <input
                  className="search"
                  placeholder="Søk"
                  value={currentSearchTerm}
                  onChange={(e) => {
                    // setSearchText(e.target.value);
                    onSearch(e.target.value);
                  }}
                />
              )}
              <hr className="dropdown-hr" />

              {children}

              {!hideFooter && currentSearchTerm && (
                <div className="dropdown--footer">
                  <Button
                    type="white"
                    label="Cancel"
                    style={{ width: '140px' }}
                    onClick={() => {
                      // setSearchText('');
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                  />
                  <Button
                    label="Apply"
                    style={{ width: '140px' }}
                    onClick={onApply}
                  />
                </div>
              )}
            </div>
          )}
        </ResizableBox>
      ) : (
        showDropDown && (
          <div
            id={`${selectMenu ? 'select-menu' : ''}`}
            className={classNames(
              // eslint-disable-next-line no-nested-ternary
              dropDownElement === 'default'
                ? `dropdown-select-drop-down`
                : dropDownComponent === 'default'
                ? `dropdown-select-drop-down-element`
                : `userTable-dropdown`,
              dropdownPanelClasses
            )}
            ref={dropDownRef}
          >
            {!hideBack && (
              <div className="back-to-filters">
                <i className="ri-arrow-left-s-line" />
                <span
                  onClick={() => {
                    if (handleBack) {
                      handleBack();
                    }
                    // setSearchText('');
                    if (onSearch) {
                      onSearch('');
                    }

                    // setShowDropDown(false);
                  }}
                >
                  Back to filters
                </span>
              </div>
            )}
            {onSearch && (
              <input
                className="search"
                placeholder="Search units"
                value={currentSearchTerm}
                onChange={(e) => {
                  // setSearchText(e.target.value);
                  onSearch(e.target.value);
                }}
              />
            )}
            {children}

            {!hideFooter && currentSearchTerm && (
              <div className="dropdown--footer">
                <Button
                  type="white"
                  label="Cancel"
                  style={{ width: '140px' }}
                  onClick={() => {
                    // setSearchText('');
                    if (onCancel) {
                      onCancel();
                    }
                  }}
                />
                <Button
                  label="Apply"
                  style={{ width: '140px' }}
                  onClick={onApply}
                />
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};
