import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './index.scss';

interface Props {
  children?: ReactNode;
  contentClasses?: string;
  itemClasses?: string;
  type?: 'loginAsUser' | 'notification';
}
export const HeaderAlertsInfo = ({
  contentClasses,
  itemClasses,
  children,
  type = 'loginAsUser'
}: Props) => {
  return (
    <div>
      <div
        className={classNames(
          'header-alerts-info-content',
          type === 'loginAsUser'
            ? 'login-as-user-content'
            : 'notification-content',
          contentClasses
        )}
      >
        <div
          className={classNames(
            'header-alerts-info-items',
            type === 'loginAsUser' ? 'login-as-user-item' : 'notification-item',
            itemClasses
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
