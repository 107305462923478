export const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

export const createCurrentGuidedTourStep = (
  parentDivClass: string,
  currentStep: string
) => {
  const newNode = document.createElement('span');
  newNode.innerText = currentStep;
  newNode.className = 'shepherd-current-step';

  const parentDiv = document.querySelector(
    `.${parentDivClass} > .shepherd-content`
  ) as HTMLElement;

  const header = parentDiv.querySelector('.shepherd-header');
  parentDiv.insertBefore(newNode, header);
};

// Store cookieConsent properties in localStorage
export const updateCookieConsent = (
  isCookieConsent: boolean,
  isAcceptCookies: boolean
) => {
  try {
    const storedData = localStorage.getItem('cookieConsent')
      ? JSON.parse(localStorage.getItem('cookieConsent') || '')
      : {};

    storedData.isCookieConsent = isCookieConsent;
    storedData.isAcceptCookies = isAcceptCookies;

    localStorage.setItem('cookieConsent', JSON.stringify(storedData));
  } catch (error) {
    console.error('Error updating cookieConsent:', error);
  }
};

// Read cookieConsent properties in localStorage
export const readCookieConsent = () => {
  try {
    const storedData = localStorage.getItem('cookieConsent')
      ? JSON.parse(localStorage.getItem('cookieConsent') || '')
      : {
          isCookieConsent: true,
          isAcceptCookies: false
        };

    return {
      isCookieConsent: storedData.isCookieConsent as boolean,
      isAcceptCookies: storedData.isAcceptCookies as boolean
    };
  } catch (error) {
    console.error('Error reading cookieConsent:', error);
    return {
      isCookieConsent: true,
      isAcceptCookies: false
    };
  }
};
