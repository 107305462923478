import { request } from 'services/api';

const Home = {
  getActiveSurveys: (pageNumber: number, pageSize: number) =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/home/get-active-surveys?pageNumber=${pageNumber}&pageSize=${pageSize}`
    ),
  getSurveyDataByYear: (year: number, pageNumber: number, pageSize: number) =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/home/get-survey-data-by-year?year=${year}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    ),
  getSurveyByStatus: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/home/get-survey-by-status`
    )
};

export default Home;
