import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  InlineWrapperCalendar,
  InlineWrapperStatuses,
  InlineWrapperSurveys,
  InlineWrapperTabs,
  InlineWrapperWithMargin
} from 'helpers/skeleton';
import { Divider } from '../Divider';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';

export const SummariesSkeleton = () => {
  return (
    <div className="summaries-skeleton">
      <Skeleton
        count={4}
        wrapper={InlineWrapperWithMargin}
        inline
        height={94}
      />
    </div>
  );
};

export const StatusesSkeleton = () => {
  return (
    <div className="home-loading-skeletons">
      <div className="title-skeleton">
        <Skeleton width={160} height={30} />
        {/* <Skeleton width={160} height={20} /> */}
      </div>
      <Skeleton
        wrapper={InlineWrapperTabs}
        count={5}
        inline
        width={90}
        height={24}
      />
      <Divider type="solid" />
      <div className="survey-tiles-skeleton">
        <Skeleton
          count={3}
          wrapper={InlineWrapperSurveys}
          inline
          height={238}
        />
      </div>
    </div>
  );
};

export const CalendarSkeleton = () => {
  return (
    <div className="home-loading-skeletons">
      <div className="calendar-title-skeleton">
        <div className="calendar-view-type-skeleton">
          <Skeleton width={180} height={40} wrapper={InlineWrapperCalendar} />
          <Skeleton width={100} height={40} wrapper={InlineWrapperCalendar} />
        </div>
        <Skeleton
          width={90}
          height={30}
          count={3}
          inline
          wrapper={InlineWrapperStatuses}
        />
      </div>

      <div className="calendar-skeleton">
        <div className="calendar-wrapper-skeleton">
          <Skeleton width={200} height={250} />
          <Skeleton wrapper={InlineWrapperCalendar} height={250} />
        </div>
      </div>
    </div>
  );
};
