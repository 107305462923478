import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { loadingStates, responseStatus } from 'models/enum';
import {
  ActiveSurveyDetails,
  IActiveSurveyEvent,
  ICalendarEvent,
  ResponseValidation,
  SurveyDataByStatus
} from 'models/models';
import agent from 'services/service';
import { addResponseData } from 'store/responseDataHandle';
import { RootState } from 'store';

interface IHomeState {
  activeSurveys: {
    pageNumber: number;
    pageSize: number;
    activeSurveyDetails: ActiveSurveyDetails;
    activeSurveyDetailsStatus: string;
    activeSurveyDetailsError: boolean;
  };
  surveyDataByYear: {
    year: number;
    pageNumber: number;
    pageSize: number;
    surveyData: ICalendarEvent[];
    surveyDataByYearStatus: string;
    surveyDataByYearError: boolean;
  };
  surveyByStatus: {
    surveyData: SurveyDataByStatus;
    surveyByStatusLoadingStatus: string;
    surveyByStatusError: boolean;
  };
}

const initialState: IHomeState = {
  activeSurveys: {
    pageNumber: 1,
    pageSize: 5,
    activeSurveyDetails: {
      activeSurveyDetails: [],
      totalActiveSurveysCount: 0,
      totalAnswersReceivedCount: '0',
      totalPendingAnswersCount: '0',
      responseRateTotalAverage: 0
    },
    activeSurveyDetailsStatus: '',
    activeSurveyDetailsError: false
  },
  surveyDataByYear: {
    year: 0,
    pageNumber: 1,
    pageSize: 5,
    surveyData: [],
    surveyDataByYearStatus: '',
    surveyDataByYearError: false
  },
  surveyByStatus: {
    surveyData: {
      surveyActiveList: [],
      surveyPlannedList: [],
      surveyCompletedList: [],
      surveyRecentList: []
    },
    surveyByStatusLoadingStatus: '',
    surveyByStatusError: false
  }
};

export const getActiveSurveysData = createAsyncThunk(
  'home/getActiveSurveys',
  async (dataItem: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState() as RootState;
    const { pageNumber, pageSize } = state.home.activeSurveys;
    try {
      const response = await agent.Home.getActiveSurveys(pageNumber, pageSize);

      if (response.code === 200) {
        if (dataItem?.getActivitySurveyDataResponse) {
          dataItem.getActivitySurveyDataResponse(response);
        }
        return response.data;
      }

      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };

      dispatch(addResponseData(responseData));
      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSurveyDataByYearData = createAsyncThunk(
  'home/getSurveyDataByYear',
  async (year: number, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState() as RootState;
    const { pageNumber, pageSize } = state.home.surveyDataByYear;
    try {
      const response = await agent.Home.getSurveyDataByYear(
        year,
        pageNumber,
        pageSize
      );

      if (response.code === 200) {
        return response.data;
      }

      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };

      dispatch(addResponseData(responseData));
      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSurveyByStatusData = createAsyncThunk(
  'home/getSurveyByStatus',
  async (dataItem: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await agent.Home.getSurveyByStatus();

      if (response.code === 200) {
        dataItem.getStatusDataCallBack(response);
        return response.data;
      }

      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };

      dispatch(addResponseData(responseData));
      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearActiveSurveyParams: (state) => ({
      ...state,
      activeSurveys: {
        ...state.activeSurveys,
        pageNumber: 1,
        pageSize: 3
      }
    }),
    updatePageNumber: (state) => ({
      ...state,
      activeSurveys: {
        ...state.activeSurveys,
        pageNumber: state.activeSurveys.pageNumber + 1
      }
    }),
    setYearFilter: (state, { payload }) => ({
      ...state,
      surveyDataByYear: {
        ...state.surveyDataByYear,
        year: payload,
        pageNumber: 1
      }
    }),
    updateSurveyByYearPageNumber: (state) => ({
      ...state,
      surveyDataByYear: {
        ...state.surveyDataByYear,
        pageNumber: state.surveyDataByYear.pageNumber + 1
      }
    }),
    setCalendarPageNumber: (state) => ({
      ...state,
      surveyDataByYear: {
        ...state.surveyDataByYear,
        pageNumber: state.surveyDataByYear.pageNumber + 1
      }
    }),
    resetCalendarData: (state) => ({
      ...state,
      surveyDataByYear: {
        ...state.surveyDataByYear,
        surveyData: []
      }
    }),
    resetHomePageData: () => initialState
  },
  extraReducers: (builder) => {
    // getActiveSurveysData
    builder
      .addCase(getActiveSurveysData.pending, (state) => {
        state.activeSurveys.activeSurveyDetailsStatus = loadingStates.LOADING;
        state.activeSurveys.activeSurveyDetailsError = false;
      })
      .addCase(getActiveSurveysData.fulfilled, (state, { payload }) => {
        payload.activeSurveyDetails?.forEach((item: IActiveSurveyEvent) => {
          if (
            !state.activeSurveys.activeSurveyDetails.activeSurveyDetails.some(
              (existingItem: IActiveSurveyEvent) =>
                existingItem.surveyId === item.surveyId
            )
          ) {
            state.activeSurveys.activeSurveyDetails.activeSurveyDetails.push(
              item
            );
          }
        });
        state.activeSurveys.activeSurveyDetails.totalActiveSurveysCount =
          payload.totalActiveSurveysCount;
        state.activeSurveys.activeSurveyDetails.totalAnswersReceivedCount =
          payload.totalAnswersReceivedCount;
        state.activeSurveys.activeSurveyDetails.totalPendingAnswersCount =
          payload.totalPendingAnswersCount;
        state.activeSurveys.activeSurveyDetails.responseRateTotalAverage =
          payload.responseRateTotalAverage;
        state.activeSurveys.activeSurveyDetailsStatus = loadingStates.SUCCESS;
        state.activeSurveys.activeSurveyDetailsError = false;
      })
      .addCase(getActiveSurveysData.rejected, (state) => {
        state.activeSurveys.activeSurveyDetailsStatus = loadingStates.FAILURE;
        state.activeSurveys.activeSurveyDetailsError = true;
      });

    // getSurveyDataByYearData
    builder
      .addCase(getSurveyDataByYearData.pending, (state) => {
        state.surveyDataByYear.surveyDataByYearStatus = loadingStates.LOADING;
        state.surveyDataByYear.surveyDataByYearError = false;
      })
      .addCase(getSurveyDataByYearData.fulfilled, (state, { payload }) => {
        payload?.forEach((item: ICalendarEvent) => {
          if (
            !state.surveyDataByYear.surveyData.some(
              (existingItem) => existingItem.id === item.id
            )
          ) {
            state.surveyDataByYear.surveyData.push(item);
          }
        });
        state.surveyDataByYear.surveyDataByYearStatus = loadingStates.SUCCESS;
        state.surveyDataByYear.surveyDataByYearError = false;
      })
      .addCase(getSurveyDataByYearData.rejected, (state) => {
        state.surveyDataByYear.surveyDataByYearStatus = loadingStates.FAILURE;
        state.surveyDataByYear.surveyDataByYearError = true;
      });

    // getSurveyByStatusData
    builder
      .addCase(getSurveyByStatusData.pending, (state) => {
        state.surveyByStatus.surveyByStatusLoadingStatus =
          loadingStates.LOADING;
        state.surveyByStatus.surveyByStatusError = false;
      })
      .addCase(getSurveyByStatusData.fulfilled, (state, { payload }) => {
        state.surveyByStatus.surveyData = payload;
        state.surveyByStatus.surveyByStatusLoadingStatus =
          loadingStates.SUCCESS;
        state.surveyByStatus.surveyByStatusError = false;
      })
      .addCase(getSurveyByStatusData.rejected, (state) => {
        state.surveyByStatus.surveyByStatusLoadingStatus =
          loadingStates.FAILURE;
        state.surveyByStatus.surveyByStatusError = true;
      });
  }
});

const { actions, reducer } = homeSlice;
export const {
  updatePageNumber,
  setYearFilter,
  updateSurveyByYearPageNumber,
  setCalendarPageNumber,
  resetCalendarData,
  resetHomePageData
} = actions;
export default reducer;
