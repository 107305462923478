import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertMessage,
  Button,
  InputSimple,
  Loader,
  Logo,
  Typography
} from 'components';
import agent from 'services/service';
import './index.scss';

export const RespondentLandingPage = () => {
  const [searchParams] = useSearchParams();
  const otpParam = searchParams.get('otp') || searchParams.get('password');

  const [otp, setOtp] = useState('');
  const [isError, setIsError] = useState(false);
  const [isOTPValidateError, setIsOTPValidateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleOtpSubmit = async (otpValue: string) => {
    if (!otpValue) {
      setIsError(true);
      setIsOTPValidateError('');
    } else {
      setIsError(false);
      setIsLoading(true);
      setIsOTPValidateError('');
      try {
        const response = await agent.OtpValidate.OTPValidation(otpValue);
        if (response.code === 200) {
          setIsButtonDisabled(true);
          setIsLoading(false);
          window.location.href = `${process.env.REACT_APP_CG_OTP_REDIRECT_URL}${otpValue}`;
        } else {
          setIsLoading(false);
          setIsOTPValidateError(
            response.errors?.errorMessage ||
              'OTP is not valid. Please check and try again.'
          );
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        if (otpParam) {
          setIsOTPValidateError('Something went wrong. Please try again.');
        }
        Alert({
          title: `Det oppstod en feil. Kontakt KF support.`,
          type: 'error',
          close: true,
          icon: true
        });
      }
    }
  };

  const handleOtpSubmitOnEnter = (event: any) => {
    if (event.keyCode === 13) {
      handleOtpSubmit(otp);
    }
  };

  useEffect(() => {
    if (otpParam) {
      handleOtpSubmit(otpParam);
    }
  }, []);

  return (
    <div className="respondent-landing-container">
      <Logo
        style={{
          width: 'auto',
          height: '40px',
          position: 'relative'
        }}
      />
      <div className="header-container">
        <Typography
          variant="h3"
          color="gray-900"
          style={{
            fontFamily: 'Gimbal Grotesque',
            lineHeight: '32px'
          }}
        >
          Velkommen til undersøkelsen
        </Typography>

        {isOTPValidateError && (
          <div className="mb">
            <AlertMessage variant="error">{isOTPValidateError}</AlertMessage>
          </div>
        )}

        {otpParam && !isOTPValidateError && (
          <div className="param-otp-checking">
            <Typography
              variant="baseMedium"
              color="gray-900"
              style={{
                fontFamily: 'Gimbal Grotesque',
                fontStyle: 'normal',
                fontWeight: 400,
                textAlign: 'center'
              }}
            >
              Checking your OTP. Please wait...
            </Typography>
            <Loader />
          </div>
        )}

        {!otpParam && (
          <>
            <div>
              <Typography
                variant="baseMedium"
                color="gray-900"
                style={{
                  fontFamily: 'Gimbal Grotesque',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  textAlign: 'center'
                }}
              >
                {`Skriv inn passordet du har fått. Det spiller ingen rolle om du bruker
        store eller små bokstaver. Trykk deretter på "Fortsett"-knappen for å
        komme videre til spørreskjemaet`}
              </Typography>
            </div>

            <div className="otp-input-container">
              <Typography
                variant="baseMedium"
                color="gray-900"
                style={{
                  fontFamily: 'Gimbal Grotesque',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  textAlign: 'center'
                }}
              >
                Engangspassord:
              </Typography>

              <div className="details-item create-otp-cus-input">
                <InputSimple
                  value={otp}
                  label=""
                  labelClasses="display-label-input"
                  name="otp"
                  type="text"
                  onChange={(value) => setOtp(value)}
                  placeholder=""
                  onkeyup={(event) => handleOtpSubmitOnEnter(event)}
                  maxLength={30}
                />
              </div>

              {isError && (
                <div>
                  <Typography
                    variant="baseMedium"
                    color="gray-900"
                    style={{
                      fontFamily: 'Gimbal Grotesque',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      textAlign: 'center',
                      color: 'red'
                    }}
                  >
                    Du må fylle inn engangspassordet
                  </Typography>
                </div>
              )}
            </div>
            <div>
              <Button
                disabled={isButtonDisabled}
                label="Fortsett"
                type="primary"
                buttonClassNames="button"
                isLoading={isLoading}
                onClick={() => handleOtpSubmit(otp)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
