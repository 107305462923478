import React, { Dispatch, SetStateAction } from 'react';
import { Button, Typography } from 'components';
import { updateCookieConsent } from 'helpers';
import './index.scss';

interface IProps {
  setCookieConsent: Dispatch<
    React.SetStateAction<{
      isCookieConsent: boolean;
      isAcceptCookies: boolean;
    }>
  >;
}

export const CookieConsentBanner = ({ setCookieConsent }: IProps) => {
  const handleAcceptCookies = () => {
    updateCookieConsent(false, true);
    setCookieConsent({
      isAcceptCookies: true,
      isCookieConsent: false
    });
  };

  return (
    <div className="cookie-consent-banner-container">
      <div className="cookie-consent-content">
        <Typography
          variant="baseMedium"
          color="black"
          customClassName="cc-title"
        >
          Bedrekommune bruker informasjonskapsler
        </Typography>
        <Typography
          variant="smallRegular"
          color="black"
          customClassName="cc-text"
        >
          Informasjonskapsler gjør det mulig å analysere anonymisert
          brukerinnsikt for å forbedre funksjonalitet, brukervennlighet og
          innhold i tjenesten. Hvis du ikke godtar, vil vi kun huske ditt valg
          om å ikke lagre informasjon.
        </Typography>
      </div>

      <div className="cookie-consent-action">
        <Button
          label="Avvis"
          type="white"
          onClick={() => {
            updateCookieConsent(false, false);
            setCookieConsent({
              isAcceptCookies: false,
              isCookieConsent: false
            });
          }}
        />
        <Button label="Godta" type="primary" onClick={handleAcceptCookies} />
      </div>
    </div>
  );
};
