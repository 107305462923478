import React from 'react';
import { CalendarViewTypes } from 'models/enum';
import './index.scss';

interface IProps {
  selectedItem: string;
  setCalendarViewType: React.Dispatch<React.SetStateAction<CalendarViewTypes>>;
}

export const ButtonGroup = ({ selectedItem, setCalendarViewType }: IProps) => {
  return (
    <div className="button-group-content">
      <div
        className={`button-group-item${
          selectedItem === CalendarViewTypes.WEEK ? '--selected' : ''
        }`}
        role="presentation"
        onClick={() => setCalendarViewType(CalendarViewTypes.WEEK)}
      >
        Uke
      </div>
      <div
        className={`button-group-item${
          selectedItem === CalendarViewTypes.DAY ? '--selected' : ''
        }`}
        role="presentation"
        onClick={() => setCalendarViewType(CalendarViewTypes.DAY)}
      >
        Dag
      </div>
    </div>
  );
};
