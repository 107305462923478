import React from 'react';
import { SymbolsItem } from '../SymbolsItem';
import './index.scss';

interface Props {
  validationMessage: string;
}

export const ValidationMessages = ({ validationMessage }: Props) => {
  return (
    <div className="error-content">
      <SymbolsItem icon="ri-error-warning-fill" color="red" />
      <p className="warning-text">{validationMessage}</p>
    </div>
  );
};
