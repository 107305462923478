import React from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useAppDispatch } from 'store';
import { clearResponseData } from 'store/responseDataHandle';
import { responseStatus } from 'models/enum';
import './index.scss';

interface Props {
  id?: string;
  title?: string;
  description?: string;
  icon?: boolean;
  type?: string;
  close?: boolean;
}

export const ResponseContent = ({
  id,
  title,
  type = 'error',
  description,
  close = true,
  icon = true
}: Props) => {
  const dispatch = useAppDispatch();

  const getIcon = (type: string) => {
    switch (type) {
      case 'info':
        return 'ri-information-fill';
      case 'success':
        return 'ri-checkbox-circle-fill';
      case 'warning':
        return 'ri-alert-fill';
      case 'error':
        return 'ri-close-circle-fill';
      default:
        return 'ri-information-fill';
    }
  };

  const closeAlert = () => {
    dispatch(clearResponseData(id));
  };

  return toast(
    <div className="alert-container">
      {icon && (
        <div className="alert-icon-container">
          <i className={`alert-icon-${type} ${getIcon(type)}`} />
        </div>
      )}
      <div>
        <div className={`alert-title-${type}`}>{title}</div>
        {description && (
          <div className={`alert-description-${type}`}>
            {type === responseStatus.ERROR && isEmpty(description)
              ? 'Server Error'
              : description}
          </div>
        )}
      </div>
    </div>,
    {
      className: `alert-main-container-${type}`,
      position: 'bottom-left',
      closeButton: close,
      autoClose: 5000,
      onClose: closeAlert,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: true
    }
  );
};
