import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './index.scss';

interface IItemProps {
  children: React.ReactNode;
  width?: number;
}

interface IProps {
  children: React.ReactNode;
}

export const CarouselItem = ({ children, width }: IItemProps) => {
  return (
    <div className="carousel-item" style={{ width }}>
      {children}
    </div>
  );
};

export const Carousel = ({ children }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 2;
    } else if (newIndex >= React.Children.count(children) - 1) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 2);
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 2),
    onSwipedRight: () => updateIndex(activeIndex - 2),
    trackMouse: true
  });

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 50}%)` }}
      >
        {React.Children.map(children, (child: any) => {
          return React.cloneElement(child, { width: '50%' });
        })}
      </div>
    </div>
  );
};
