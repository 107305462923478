import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { loadingStates, responseStatus } from 'models/enum';
import {
  Auth,
  AuthBK,
  AuthorizationSettings,
  NotificationData,
  ResponseValidation,
  SurveyManipulationData,
  UrlQuery
} from 'models/models';
import agent from 'services/service';
import { addResponseData } from 'store/responseDataHandle';
import { persistor, RootState } from 'store';

interface AuthState {
  isAuthenticated: boolean;
  token: string;
  refreshToken: string;
  displayName: string;
  email: string;
  userId: string | null;
  isFirstTimeUser: boolean;
  loading: string;
  isLogInAsUser: boolean;
  loginError: boolean;
  urlQuery: UrlQuery;
  surveyManipulationData: SurveyManipulationData;
  userAuthenticationSettings: AuthorizationSettings;
  guidedTour: {
    isFirstTimeUser: boolean;
    isTourSkipped: boolean;
  };
  isShowNotification: boolean;
  notificationData: NotificationData;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: '',
  refreshToken: '',
  displayName: '',
  email: '',
  userId: null,
  isFirstTimeUser: false,
  loading: '',
  isLogInAsUser: false,
  loginError: false,
  urlQuery: {
    editSurveyId: '',
    formId: 0,
    period: 0,
    periodId: 0,
    rootOrgUnitId: 0,
    selectedSurveyRootOrgUnitId: 0,
    surveyId: 0
  },
  surveyManipulationData: {
    editSurveyId: '',
    formId: 0,
    period: 0,
    periodId: 0,
    orgUnitId: 0,
    rootOrgUnitId: 0,
    surveyName: '',
    selectedSurveyRootOrgUnitId: 0,
    surveyId: 0,
    surveyUniqueId: 0,
    surveyTemplateId: '',
    surveyDataId: '',
    isClosed: false,
    surveyYear: 0
  },
  guidedTour: {
    isFirstTimeUser: false,
    isTourSkipped: false
  },
  userAuthenticationSettings: {
    isEnableAddSurveyTemplate: false,
    isEnableStartSurvey: false,
    isVisibleSurveyOverview: false,
    isVisibleSurveySettings: false,
    isVisibleSurveyRespondents: false,
    isVisibleSurveyReports: false,
    isEnableReportUser: false,
    error: false,
    status: ''
  },
  isShowNotification: false,
  notificationData: {
    id: '',
    subject: '',
    description: '',
    enabled: false,
    lastModifiedDate: '',
    createdDate: '',
    audienceType: 0,
    status: '',
    error: false
  }
};

export const login = createAsyncThunk(
  'auth/login',
  async (auth: Auth, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await agent.Authentication.login(auth);

      if (response.code === 200) {
        return response.data;
      }

      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };
      dispatch(addResponseData(responseData));

      return thunkAPI.rejectWithValue('Error');
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const loginFromBK = createAsyncThunk(
  'auth/loginFromBK',
  async (authBK: AuthBK) => {
    const response = await agent.Authentication.loginBK(authBK);
    return response.data;
  }
);

export const loginAsAdmin = createAsyncThunk(
  'auth/loginAsAdmin',
  async (testVal: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await agent.Authentication.loginAsAdmin();
      if (response.code === 200) {
        return response.data;
      }
      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };
      dispatch(addResponseData(responseData));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getUserAuthenticationSettings = createAsyncThunk(
  'auth/getUserAuthenticationSettings',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response =
        await agent.Authentication.getAuthorizationSettingsByUser();
      if (response.code === 200) {
        return response.data;
      }
      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };
      dispatch(addResponseData(responseData));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getActiveNotifications = createAsyncThunk(
  'auth/getActiveNotifications',
  async (getResponse: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await agent.Authentication.getActiveNotifications();
      if (response.code === 200) {
        getResponse.getNotificationData(response.data);
        return response.data;
      }
      const responseData: ResponseValidation = {
        id: uuidv4(),
        type: responseStatus.ERROR,
        responseMessage: response.errors.errorMessage,
        responseMessageList: []
      };
      dispatch(addResponseData(responseData));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const clearAuthStateAsync = createAsyncThunk(
  'auth/clearAuthState',
  async (data: any, thunkAPI) => {
    // eslint-disable-next-line no-use-before-define
    thunkAPI.dispatch(clearAuthState());
    persistor.purge();
    if (!(thunkAPI.getState() as RootState).auth.isAuthenticated) {
      data.getAuthStatus(true);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => ({ ...state, ...initialState }),
    refreshAuthTokens: (state, action) => ({
      ...state,
      ...action.payload,
      isAuthenticated: action.payload.token !== '' || false
    }),
    changeLoginAsUser: (state, action) => ({
      ...state,
      isLogInAsUser: action.payload
    }),
    addUrlQuery: (state, action) => ({
      ...state,
      urlQuery: action.payload
    }),
    setSurveyManipulationData: (state, action) => ({
      ...state,
      surveyManipulationData: action.payload
    }),
    setGuidedTour: (state, { payload }) => {
      state.guidedTour = payload;
    },
    setIsNotificationData: (state, { payload }) => {
      state.isShowNotification = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = loadingStates.PENDING;
      state.loginError = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      // const jwtDecodedToken: any = jwt_decode(action.payload.token);
      return {
        ...state,
        isAuthenticated: action.payload.token !== '' || false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        displayName: action.payload.displayName,
        email: action.payload.email,
        userId: action.payload?.userId,
        isFirstTimeUser: action.payload?.isFirstTimeUser,
        loading: loadingStates.SUCCESS,
        loginError: false
      };
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = loadingStates.FAILURE;
      state.loginError = true;
    });

    builder.addCase(loginFromBK.pending, (state) => {
      state.loading = loadingStates.PENDING;
    });
    builder.addCase(loginFromBK.fulfilled, (state, action) => {
      const jwtDecodedToken: any = jwt_decode(action.payload.token);
      return {
        ...state,
        isAuthenticated: action.payload.token !== '' || false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        displayName: action.payload.displayName,
        email: jwtDecodedToken.email,
        loading: loadingStates.IDLE
      };
    });
    builder.addCase(loginAsAdmin.pending, (state) => {
      state.loading = loadingStates.PENDING;
    });
    builder.addCase(loginAsAdmin.fulfilled, (state, action) => {
      const jwtDecodedToken: any = jwt_decode(action.payload.token);
      return {
        ...state,
        isAuthenticated: action.payload.token !== '' || false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        displayName: action.payload.displayName,
        isLogInAsUser: false,
        email: jwtDecodedToken.email,
        loading: loadingStates.IDLE
      };
    });
    builder.addCase(loginAsAdmin.rejected, (state) => {
      // state.status = 'failure';
      // state.error = true;
    });

    // getUserAuthenticationSettings
    builder.addCase(getUserAuthenticationSettings.pending, (state) => {
      state.userAuthenticationSettings.status = loadingStates.PENDING;
      state.userAuthenticationSettings.error = false;
    });
    builder.addCase(
      getUserAuthenticationSettings.fulfilled,
      (state, action) => {
        state.userAuthenticationSettings = action.payload;
        state.userAuthenticationSettings.status = loadingStates.SUCCESS;
        state.userAuthenticationSettings.error = false;
      }
    );
    builder.addCase(getUserAuthenticationSettings.rejected, (state) => {
      state.userAuthenticationSettings.status = loadingStates.FAILURE;
      state.userAuthenticationSettings.error = true;
    });

    // getActiveNotifications
    builder.addCase(getActiveNotifications.pending, (state) => {
      state.notificationData.status = loadingStates.PENDING;
      state.notificationData.error = false;
    });
    builder.addCase(getActiveNotifications.fulfilled, (state, action) => {
      state.notificationData = action.payload;
      if (action.payload) {
        console.log(action.payload.enabled);
        // setIsNotificationData(true);
      }
      state.notificationData.status = loadingStates.SUCCESS;
      state.notificationData.error = false;
    });
    builder.addCase(getActiveNotifications.rejected, (state) => {
      state.notificationData.status = loadingStates.FAILURE;
      state.notificationData.error = true;
    });
  }
});
const { actions, reducer } = authSlice;

export const {
  clearAuthState,
  refreshAuthTokens,
  changeLoginAsUser,
  addUrlQuery,
  setSurveyManipulationData,
  setGuidedTour,
  setIsNotificationData
} = actions;
export default reducer;
