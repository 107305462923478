import { request } from 'services/api';

const OtpValidate = {
  OTPValidation: (otp: string) =>
    request.basePost<any | null>(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/otp/validate`,
      JSON.stringify({
        otp
      })
    )
};

export default OtpValidate;
