import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Alert } from 'components/atoms';
import { store, persistor } from 'store';
import { clearAuthState, refreshAuthTokens } from 'store/features/Auth';

export const instance = axios.create({
  // adapter: cache.adapter,
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

export const baseInstance = axios.create({
  // adapter: cache.adapter,
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

let refreshing_token: any = null;

const logout = async () => {
  persistor.purge();
  localStorage.removeItem('MBK_Authentication');
  window.location.href = '/users/logout';
};

function getLocalAccessToken() {
  const { getState } = store;
  const { auth } = getState();
  const { token } = auth;
  if (token) {
    return token;
  }
}
function getLocalRefreshToken() {
  const { getState } = store;
  const { auth } = getState();
  const { refreshToken } = auth;
  if (refreshToken) {
    return refreshToken;
  }
}

const handlerRefreshToken = () => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_USER_API}${process.env.REACT_APP_BASE_API_VERSION}/auth/refresh-tokens`,
    {
      accessToken: getLocalAccessToken(),
      refreshToken: getLocalRefreshToken()
    }
  );
};

const handleLocalStoreAuth = ({ accessToken, refreshToken }: any) => {
  const { dispatch } = store;
  dispatch(
    refreshAuthTokens({
      token: accessToken,
      refreshToken
    })
  );
};

instance.interceptors.request.use(
  (config) => {
    const { getState } = store;
    const { auth } = getState();
    const { token } = auth;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    if (token && token !== '') {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  () => {
    Alert({
      title: `Det oppstod en feil. Kontakt KF support.`,
      type: 'error',
      close: true,
      icon: true
    });
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response } = error;
    const originalConfig = error.config;

    if (response.status === 401 && !originalConfig._retry) {
      if (response.data === 'TokenExpired') {
        originalConfig._retry = true;
        try {
          refreshing_token = refreshing_token || handlerRefreshToken();
          const res = await refreshing_token;
          refreshing_token = null;
          const { accessToken, refreshToken } = res.data.data;
          handleLocalStoreAuth({ accessToken, refreshToken });
          return instance(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            logout();
            return Promise.reject(_error.response.data);
          }
          logout();
          return Promise.reject(_error);
        }
      } else {
        logout();
      }
    } else {
      Alert({
        title: `Det oppstod en feil. Kontakt KF support.`,
        type: 'error',
        close: true,
        icon: true
      });
    }
    if (response.status === 403 && response.data) {
      window.location.href = './403';
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

baseInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response } = error;
    const originalConfig: any = error.config;

    if (response.status === 401 && !originalConfig._retry) {
      if (response.data === 'TokenExpired') {
        originalConfig._retry = true;

        try {
          refreshing_token = refreshing_token || handlerRefreshToken();
          const res = await refreshing_token;
          refreshing_token = null;

          const { accessToken, refreshToken } = res.data.data;
          handleLocalStoreAuth({ accessToken, refreshToken });

          return instance(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            logout();
            return Promise.reject(_error.response.data);
          }

          logout();
          return Promise.reject(_error);
        }
      } else if (!response.config.url.includes('auth/login')) {
        logout();
      }
    } else {
      Alert({
        title: `Det oppstod en feil. Kontakt KF support.`,
        type: 'error',
        close: true,
        icon: true
      });
    }
    if (response.status === 403 && response.data) {
      window.location.href = './403';
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

const responseAll = <T>(response: AxiosResponse<T>) => response;
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const request = {
  baseGet: <T>(url: string) => baseInstance.get<T>(url).then(responseBody),

  basePost: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    baseInstance
      .post<T>(url, body, {
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'application/json'
        }
      })
      .then(responseBody),

  get: <T>(url: string, config?: AxiosRequestConfig) =>
    instance.get<T>(url, config).then(responseBody),
  post: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    instance.post<T>(url, body, config).then(responseBody),
  purePost: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    instance.post<T>(url, body, config).then(responseAll),
  put: <T>(url: string, body: {}) =>
    instance.put<T>(url, body).then(responseBody)
};
