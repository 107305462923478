/* eslint-disable no-undef */
// import axios from 'axios';
import { Auth, AuthBK } from 'models/models';
import { request } from 'services/api';

const Authentication = {
  login: (auth: Auth) =>
    request.basePost<any | null>(
      `${process.env.REACT_APP_USER_API}${process.env.REACT_APP_BASE_API_VERSION}/auth/login`,
      JSON.stringify(auth)
    ),
  loginBK: (authBK: AuthBK) =>
    request.basePost<any | null>(
      `${process.env.REACT_APP_USER_API}/${process.env.REACT_APP_BASE_API_VERSION}/auth/login-bk`,
      authBK
    ),
  loginAsAdmin: () =>
    request.post<any | null>(
      `${process.env.REACT_APP_USER_API}/${process.env.REACT_APP_BASE_API_VERSION}/auth/loginasadmin`,
      {}
    ),
  loginasuserUserid: () =>
    request.post<any | null>(
      `${process.env.REACT_APP_USER_API}${process.env.REACT_APP_BASE_API_VERSION}/auth/loginasuser-userid`,
      {}
    ),
  getAuthorizationSettingsByUser: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/surveys/get-authorization-settings`
    ),
  getActiveNotifications: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_ADMIN_TOOLS_API}${process.env.REACT_APP_BASE_API_VERSION}/announcement/get-active-announcement`
    )
};

export default Authentication;
