import React, { useEffect, useState } from 'react';
import Joyride, {
  CallBackProps,
  STATUS,
  ACTIONS,
  EVENTS,
  Step
} from 'react-joyride';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  TourNames,
  closeTour,
  nextStep,
  updateTourItem,
  updateUserGuidedTourData
} from 'store/features/GuidedTour';
import { setGuidedTour } from 'store/features/Auth';
import { CustomTooltip } from './CustomTooltip';

const steps: Step[] = [
  {
    title: 'Sammendrag av spørreundersøkelser',
    content: (
      <span>
        Her kan du se et sammendrag av undersøkelsene dine. Klikk på ikonet{' '}
        <i className="ri-information-fill" /> for mer informasjon.
      </span>
    ),
    target: '.guided-tour-1-summary',
    placement: 'bottom',
    disableScrolling: true,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    }
  },
  {
    title: 'Spørreundersøkelser',
    content:
      'Her kan du se status på undersøkelsene dine. Klikk på fanene for å navigere og se hvordan det står til.',
    target: '.guided-tour-2-status',
    placement: 'right',
    disableScrolling: true,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    }
  },
  {
    title: 'Spørreundersøkelser',
    content: 'Her kan du se tidslinjen for undersøkelsene dine for valgt år.',
    target: '.guided-tour-3-calendar',
    placement: 'right',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    }
  },
  {
    title: 'Start en spørreundersøkelse',
    content: 'Her kan du starte en ny undersøkelse.',
    target: '.guided-tour-4-header',
    placement: 'left',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    }
  }
];

export const GuidedTour = () => {
  const dispatch = useAppDispatch();

  const isActiveGuidedTour = useAppSelector(
    (state: RootState) => state.guidedTour.isRun
  );

  const tourData = useAppSelector((state: RootState) =>
    state.guidedTour.tour.find((tour) => tour.moduleName === TourNames.HOME)
  );

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data;

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      if (index === 0 && action === ACTIONS.NEXT) {
        dispatch(nextStep());
        dispatch(
          updateUserGuidedTourData({
            ...tourData!,
            currentStep: 1
          })
        );
      } else if (index === 1 && action === ACTIONS.NEXT) {
        dispatch(nextStep());
        dispatch(
          updateUserGuidedTourData({
            ...tourData!,
            currentStep: 2
          })
        );
      } else if (index === 2 && action === ACTIONS.NEXT) {
        dispatch(nextStep());
        dispatch(
          updateUserGuidedTourData({
            ...tourData!,
            currentStep: 3
          })
        );
      }
    }

    if (action === ACTIONS.SKIP) {
      dispatch(closeTour());
      dispatch(setGuidedTour({ isFirstTimeUser: false, isTourSkipped: true }));
      dispatch(
        updateTourItem({
          isTourCompleted: false,
          isTourStarted: true,
          isTourSkipped: true
        })
      );
      dispatch(
        updateUserGuidedTourData({
          ...tourData!,
          isTourCompleted: false,
          isTourStarted: true,
          isTourSkipped: true
        })
      );
    } else if (action === ACTIONS.CLOSE) {
      dispatch(closeTour());
      dispatch(
        updateTourItem({
          isTourCompleted: true,
          isTourStarted: true,
          isTourSkipped: false
        })
      );
      dispatch(
        updateUserGuidedTourData({
          ...tourData!,
          isTourCompleted: true,
          isTourStarted: true,
          isTourSkipped: false
        })
      );
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      tooltipComponent={CustomTooltip}
      continuous
      hideCloseButton
      run={isActiveGuidedTour}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={steps}
      stepIndex={tourData?.currentStep}
      disableOverlayClose
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: 'transparent',
          spotlightShadow: 'none',
          arrowColor: '#2563EB'
        },
        spotlight: {
          backgroundColor: 'transparent'
        }
      }}
    />
  );
};
