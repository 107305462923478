import React, { useEffect, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import {
  Button,
  Header,
  SurveyStatuses,
  SurveySummaries,
  Tabs,
  TopNavBar,
  Typography,
  ButtonGroup,
  Calendar,
  EmptyState,
  StatusesSkeleton,
  CalendarSkeleton,
  GuidedTourModal,
  ProgressSpinnerElement,
  GuidedTour
} from 'components';
import { CalendarViewTypes, loadingStates } from 'models/enum';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  getActiveSurveysData,
  getSurveyByStatusData,
  getSurveyDataByYearData,
  resetHomePageData,
  setCalendarPageNumber,
  setYearFilter
} from 'store/features/Home';
import { isEmpty } from 'lodash';
import { getYearDataList } from 'store/masterData';
import {
  ITourItem,
  TourNames,
  getUserGuidedTourData,
  setShowGuidedTourModal
} from 'store/features/GuidedTour';
import { setGuidedTour } from 'store/features/Auth';
import './index.scss';

export const HomeView = () => {
  const dispatch = useAppDispatch();

  const { surveyData: surveyByYearData, surveyDataByYearStatus } =
    useAppSelector((state: RootState) => {
      return state.home.surveyDataByYear;
    });

  const { surveyData: surveyByStatusData, surveyByStatusLoadingStatus } =
    useAppSelector((state: RootState) => state.home.surveyByStatus);

  const selectedYear = useAppSelector(
    (state: RootState) => state.home.surveyDataByYear.year
  );

  const guidedTour = useAppSelector(
    (state: RootState) => state.auth.guidedTour
  );

  const activeSurveyDetailsStatus = useAppSelector(
    (state: RootState) => state.home.activeSurveys.activeSurveyDetailsStatus
  );

  const { isRun: isRunGuidedTour, showGuidedTourModal } = useAppSelector(
    (state: RootState) => state.guidedTour
  );

  const isActiveGuidedTour = useAppSelector(
    (state: RootState) => state.guidedTour.isRun
  );

  const tourData = useAppSelector((state: RootState) =>
    state.guidedTour.tour.find((tour) => tour.moduleName === TourNames.HOME)
  );

  const authenticationDetails = useAppSelector((state: RootState) => {
    return state.auth.userAuthenticationSettings;
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [calendarViewType, setCalendarViewType] = useState<CalendarViewTypes>(
    CalendarViewTypes.WEEK
  );

  const handleGetMoreCalendarData = () => {
    dispatch(setCalendarPageNumber());
    dispatch(getSurveyDataByYearData(selectedYear));
  };

  const emptyStateProperties = useMemo(() => {
    return {
      header: 'Ingen nylige undersøkelser',
      description: !authenticationDetails.isEnableReportUser
        ? 'Kom i gang og start en undersøkelse'
        : 'Se resultater fra tidligere undersøkelser',
      buttonText: !authenticationDetails.isEnableReportUser
        ? 'Start en undersøkelse'
        : 'Se tidligere undersøkelser',
      onClick: () => {
        const url = !authenticationDetails.isEnableReportUser
          ? `${process.env.REACT_APP_SURVEY_BASE_URL}templates/`
          : `${process.env.REACT_APP_SURVEY_BASE_URL}`;
        window.location.href = url;
      }
    };
  }, [authenticationDetails.isEnableReportUser]);

  useEffect(() => {
    if (
      surveyDataByYearStatus === loadingStates.SUCCESS &&
      surveyByStatusLoadingStatus === loadingStates.SUCCESS &&
      activeSurveyDetailsStatus === loadingStates.SUCCESS &&
      guidedTour.isFirstTimeUser &&
      !guidedTour.isTourSkipped
    )
      dispatch(getUserGuidedTourData()).then((response) => {
        const data = response.payload as ITourItem[];

        const allModulesCompleted = Object.values(TourNames).every(
          (moduleName) => {
            const tourItem = data.find(
              (item) => item.moduleName === moduleName
            );
            return tourItem ? tourItem.isTourCompleted : false;
          }
        );

        if (allModulesCompleted) {
          dispatch(
            setGuidedTour({ isFirstTimeUser: false, isTourSkipped: false })
          );
        } else {
          dispatch(setShowGuidedTourModal(true));
        }
      });
  }, [
    surveyDataByYearStatus,
    surveyByStatusLoadingStatus,
    activeSurveyDetailsStatus,
    guidedTour.isFirstTimeUser
  ]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getYearDataList({
        getYearListCallBack: (yearResponse: any) => {
          if (yearResponse) {
            dispatch(setYearFilter(yearResponse.data[0].year));
            dispatch(
              getActiveSurveysData({
                getActivitySurveyDataResponse: (activityDataResponse: any) => {
                  if (activityDataResponse) {
                    dispatch(
                      getSurveyByStatusData({
                        getStatusDataCallBack: (statusResponse: any) => {
                          if (statusResponse) {
                            setIsLoading(false);
                            dispatch(
                              getSurveyDataByYearData(yearResponse.data[0].year)
                            );
                          }
                        }
                      })
                    );
                  }
                }
              })
            );
          }
        }
      })
    );

    return () => {
      dispatch(resetHomePageData());
    };
  }, []);

  return (
    <>
      <TopNavBar />
      <div
        className="body-wrapper"
        style={isRunGuidedTour ? { overflow: 'initial' } : {}}
      >
        <div className="body-wrapper-inner">
          <Header />
          <SurveySummaries />

          {surveyByStatusLoadingStatus === loadingStates.LOADING ? (
            <StatusesSkeleton />
          ) : (
            <>
              <div className="see-all-surveys-container">
                <div className="see-all-surveys-title">
                  <Typography
                    variant="h3"
                    color="brand-midnight"
                    style={{ margin: 0, padding: 0 }}
                    customClassName="guided-tour-2-status"
                  >
                    Status
                  </Typography>
                </div>

                {/* <Button
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_SURVEY_BASE_URL}`;
                  }}
                  label="Se alle undersøkelser"
                  type="link"
                /> */}
              </div>

              <div className="home-survey-tabs">
                <Tabs tabIndex={tabIndex} onSelect={setTabIndex}>
                  <TabList>
                    <Tab>Nylig</Tab>
                    <Tab>
                      Aktiv (
                      {surveyByStatusData.surveyActiveList
                        ? surveyByStatusData?.surveyActiveList.length
                        : 0}
                      )
                    </Tab>
                    <Tab>
                      Planlagt (
                      {surveyByStatusData.surveyPlannedList
                        ? surveyByStatusData?.surveyPlannedList.length
                        : 0}
                      )
                    </Tab>
                    <Tab>
                      Fullført (
                      {surveyByStatusData.surveyCompletedList
                        ? surveyByStatusData?.surveyCompletedList.length
                        : 0}
                      )
                    </Tab>
                  </TabList>
                  <TabPanel>
                    {!isEmpty(surveyByStatusData.surveyRecentList) ? (
                      <SurveyStatuses
                        data={surveyByStatusData.surveyRecentList}
                      />
                    ) : (
                      <EmptyState
                        {...emptyStateProperties}
                        buttonDisabled={
                          !authenticationDetails.isEnableAddSurveyTemplate
                        }
                        size="default"
                        customClassName="surveys-empty-state"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {!isEmpty(surveyByStatusData.surveyActiveList) ? (
                      <SurveyStatuses
                        data={surveyByStatusData.surveyActiveList}
                      />
                    ) : (
                      <EmptyState
                        {...emptyStateProperties}
                        buttonDisabled={
                          !authenticationDetails.isEnableAddSurveyTemplate
                        }
                        size="default"
                        customClassName="surveys-empty-state"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {!isEmpty(surveyByStatusData.surveyPlannedList) ? (
                      <SurveyStatuses
                        data={surveyByStatusData.surveyPlannedList}
                      />
                    ) : (
                      <EmptyState
                        {...emptyStateProperties}
                        buttonDisabled={
                          !authenticationDetails.isEnableAddSurveyTemplate
                        }
                        size="default"
                        customClassName="surveys-empty-state"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {!isEmpty(surveyByStatusData.surveyCompletedList) ? (
                      <SurveyStatuses
                        data={surveyByStatusData.surveyCompletedList}
                      />
                    ) : (
                      <EmptyState
                        {...emptyStateProperties}
                        buttonDisabled={
                          !authenticationDetails.isEnableAddSurveyTemplate
                        }
                        size="default"
                        customClassName="surveys-empty-state"
                      />
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}

          {isLoading ? (
            <CalendarSkeleton />
          ) : (
            <>
              <div className="calendar-container">
                <div className="calendar-header-wrapper">
                  <div className="title-view-type-wrapper">
                    <div className="calendar-title">
                      <Typography
                        variant="h3"
                        color="brand-midnight"
                        style={{ margin: 0, padding: 0 }}
                        customClassName="guided-tour-3-calendar"
                      >
                        Kalender
                      </Typography>
                    </div>

                    <ButtonGroup
                      selectedItem={calendarViewType}
                      setCalendarViewType={setCalendarViewType}
                    />
                  </div>

                  <div className="statuses-indicator-container">
                    <div className="status-indicator">
                      <div className="status-color--active" />
                      <Typography variant="baseMedium" color="gray-900">
                        Aktiv
                      </Typography>
                    </div>

                    <div className="status-indicator">
                      <div className="status-color--planned" />
                      <Typography variant="baseMedium" color="gray-900">
                        Planlagt
                      </Typography>
                    </div>

                    <div className="status-indicator">
                      <div className="status-color--finished" />
                      <Typography variant="baseMedium" color="gray-900">
                        Fullført
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <Calendar
                calendarViewType={calendarViewType}
                calendarData={surveyByYearData}
              />

              <div className="calendar-load-more-wrapper">
                {surveyDataByYearStatus === loadingStates.LOADING ? (
                  <ProgressSpinnerElement size="default" color="gray" />
                ) : (
                  <Button
                    label="Last inn mer"
                    onClick={handleGetMoreCalendarData}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {showGuidedTourModal && (
        <GuidedTourModal
          showGuidedTour={showGuidedTourModal}
          closeGuidedTour={(value) => dispatch(setShowGuidedTourModal(value))}
        />
      )}

      {isRunGuidedTour && <GuidedTour />}
    </>
  );
};
