import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, SymbolsItem } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { clearAuthState, clearAuthStateAsync } from 'store/features/Auth';
import './index.scss';

interface IProps {
  setShowProfileDropDown: Dispatch<SetStateAction<boolean>>;
}

export const UserProfileDropdown = ({ setShowProfileDropDown }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dropDownRef = useRef<any>(null);
  const userDisplayName = useAppSelector(({ auth }) => auth.displayName);
  const userEmail = useAppSelector(({ auth }) => auth.email);

  const handleViewProfile = () => {
    setShowProfileDropDown(false);
    window.location.href = '/users/profile';
  };

  const data = {
    getAuthStatus: (authStatus: boolean) => {
      setTimeout(() => {
        if (!authStatus) {
          window.location.href = '/login';
        }
      }, 2000);
    }
  };

  const userLogout = () => {
    setShowProfileDropDown(false);
    dispatch(clearAuthStateAsync(data));
    window.location.href = '/users/logout';
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        dropDownRef?.current &&
        !dropDownRef.current?.contains(event?.target)
      ) {
        setShowProfileDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <div className="list-item-content" ref={dropDownRef}>
      <div className="drop-down-top-content ">
        <div className="user-profile-dropdown-content">
          <div className="avatar-content-drp">
            <Avatar
              size="large"
              initial={`${userDisplayName?.match(/\b(\w)/g)?.join('')}`}
            />
          </div>
          <p className="user-profile-header">{userDisplayName}</p>
          <p className="user-profile-sub-header">{userEmail}</p>
          <div style={{ paddingBottom: '20px' }}>
            <Button
              onClick={handleViewProfile}
              label="Min side"
              type="white"
              size="base"
            />
          </div>
        </div>
      </div>
      <hr className="horizontal-line " />
      <div className="user-profile-footer" onClick={userLogout}>
        <div>
          <SymbolsItem icon="ri-logout-box-line" color="black" />
        </div>
        <p className="user-profile-header footer-logout">Logg ut</p>
      </div>
    </div>
  );
};
