import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { Typography } from 'components';
import './index.scss';

export const CustomTooltip = ({
  skipProps,
  closeProps,
  index,
  isLastStep,
  primaryProps,
  step,
  tooltipProps,
  size
}: TooltipRenderProps) => {
  const currentStep = index + 1;

  return (
    <div className="tour-custom-tooltip" {...tooltipProps}>
      <div className="tour-step-counter">
        <Typography variant="smallBold" color="white">
          {currentStep}/{size}
        </Typography>
      </div>

      {step.title && (
        <div className="tour-step-title">
          <Typography
            variant="baseMedium"
            color="white"
            customClassName="tour-title"
          >
            {step.title}
          </Typography>
        </div>
      )}

      <div className="tour-step-content">
        <Typography
          variant="baseRegular"
          color="white"
          customClassName="tour-content"
        >
          {step.content}
        </Typography>
      </div>

      <div className="tour-tooltip-footer">
        {currentStep !== size && (
          <button
            type="button"
            className="tour-controls tour-stop-button"
            {...skipProps}
          >
            Stopp omvisning
          </button>
        )}
        {isLastStep ? (
          <button
            type="button"
            className="tour-controls tour-next-button"
            {...closeProps}
          >
            Den er grei
          </button>
        ) : (
          <button
            type="button"
            className="tour-controls tour-next-button"
            {...primaryProps}
          >
            Neste
          </button>
        )}
      </div>
    </div>
  );
};
