import React from 'react';
import {
  Button,
  InputSimple as Input,
  Modal,
  ModalBody,
  ModalFooter,
  ReactPortal,
  SymbolsItem,
  Typography
} from 'components';
import './index.scss';

interface IProps {
  showHelpAndSupport: boolean;
  closeHelpAndSupport: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuggestionItem = () => {
  return (
    <div className="suggestion-item-container">
      <div className="suggestion-icon">
        <SymbolsItem
          icon="ri-article-line"
          customClass="suggestion-icon-component"
        />
      </div>

      <div className="suggestion-content">
        <Typography
          variant="baseMedium"
          customClassName="suggestion-title help-support-content-color"
        >
          Standardundersøkelser er undersøkelser utarbeidet av KF, og kan brukes
          av alle.
        </Typography>
        <Typography
          variant="baseRegular"
          customClassName="suggestion-paragraph"
        >
          De store fordelene med standardundersøkelser er at de er faglig
          sterke, og at du kan sammenligne resultatene dine med andre kommuner
          og landsgjennomsnitt. Egne undersøkelser kan du selve lage fra bunnen
          av, eller du kan ta utgangspunkt i en.....
        </Typography>
        <Button
          label="Se mer"
          type="icon-link"
          icon="ri-share-box-line"
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export const HelpAndSupport = ({
  showHelpAndSupport,
  closeHelpAndSupport
}: IProps) => {
  const startTour = (tourContext: any) => {
    return (
      <Button
        label="Få omvisning"
        type="primary"
        onClick={() => {
          closeHelpAndSupport(false);
          tourContext.start();
        }}
      />
    );
  };

  return (
    <ReactPortal wrapperId="help-support-modal">
      <Modal
        title="Hjelp og støtte"
        size="large"
        show={showHelpAndSupport}
        customClasses="help-and-support-modal"
        onClose={() => {
          closeHelpAndSupport(false);
        }}
      >
        <ModalBody>
          <Input
            search
            name="help-support-search"
            inputClasses="help-support-search"
            placeholder="Søk"
            type="text"
            noBackground
            onChange={() => {}}
          />
          <span className="custom-divider" />
          <Typography
            variant="h4"
            customClassName="help-support-content-title help-support-content-color"
          >
            Forslag
          </Typography>

          <div className="help-support-content-wrapper">
            <SuggestionItem />
            <SuggestionItem />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Se omvisninger"
            type="icon-link"
            icon="ri-arrow-right-line"
            onClick={() => {
              closeHelpAndSupport(false);
            }}
          />
        </ModalFooter>
      </Modal>
    </ReactPortal>
  );
};
