import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { createWrapperAndAppendToBody } from 'helpers';

interface IProps {
  children: ReactNode;
  wrapperId: string;
}

export const ReactPortal = ({ children, wrapperId }: IProps) => {
  let element = document.getElementById(wrapperId);

  if (!element) {
    element = createWrapperAndAppendToBody(wrapperId);
  }

  return createPortal(children, element);
};
