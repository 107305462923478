import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import './index.scss';

interface Props {
  id?: string;
  label?: string;
  name: string;
  placeholder: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  labelClasses?: string;
  inputClasses?: string;
}

export const Textarea = ({
  id,
  label,
  name,
  placeholder,
  required,
  disabled,
  helperText,
  labelClasses,
  inputClasses
}: Props) => {
  const [field, meta] = useField(name);

  return (
    <div>
      {label && (
        <label
          className={classNames('input-lable', labelClasses)}
          htmlFor={id || name}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <textarea
        className={classNames(
          `textarea ${
            required && meta.touched && meta.error ? 'input-error' : ''
          } ${disabled ? 'input-disabled' : ''}`,
          inputClasses
        )}
        id={id || name}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
      {!meta.error && helperText && (
        <div className="input-helper-text">{helperText}</div>
      )}
      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </div>
  );
};

export const TextareaSimple = ({
  id,
  label,
  name,
  placeholder,
  required,
  disabled,
  helperText,
  labelClasses,
  inputClasses,
  value,
  style,
  onChange
}: Props) => {
  return (
    <>
      {label && (
        <label
          className={classNames('input-lable', labelClasses)}
          htmlFor={id || name}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <textarea
        className={classNames(
          `textarea  ${disabled ? 'input-disabled' : ''}`,
          inputClasses
        )}
        id={id || name}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        value={value}
      />
    </>
  );
};
