import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage, LoginPage, RespondentLandingPage } from 'components';
import { RootState, useAppSelector } from 'store';
import 'remixicon/fonts/remixicon.css';

function App() {
  const { isAuthenticated } = useAppSelector((state: RootState) => {
    return state.auth;
  });

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? <HomePage /> : <LoginPage />}
      />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path={process.env.REACT_APP_OTP_URL}
        element={<RespondentLandingPage />}
      />
    </Routes>
  );
}

export default App;
