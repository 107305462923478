import Authentication from './auth';
import OtpValidate from './otpValidate';
import Home from './home';
import MasterData from './masterData';
import GuidedTour from './guidedTour';

const agent = {
  Authentication,
  OtpValidate,
  Home,
  MasterData,
  GuidedTour
};

export default agent;
