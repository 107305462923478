import { request } from 'services/api';

const MasterData = {
  getYearList: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_SURVEY_API}${process.env.REACT_APP_BASE_API_VERSION}/Surveys/get-year-list`
    )
};

export default MasterData;
