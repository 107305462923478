/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button';
import { Typography } from '../Typography';
import './index.scss';

interface Props {
  imageSrc?: string;
  header?: string;
  description: string;
  size?: 'default' | 'small';
  buttonText?: string;
  buttonDisabled?: boolean;
  onClick?: () => void;
  icon?: string;
  customClassName?: string;
}

export const EmptyState = ({
  imageSrc,
  header,
  description,
  size = 'default',
  buttonText,
  buttonDisabled,
  onClick,
  icon = undefined,
  customClassName = undefined
}: Props) => {
  return (
    <div className={classNames(`empty-state-content-${size}`, customClassName)}>
      {imageSrc && (
        <div className="empty-state-image-container">
          <div
            className={`image-content-${size}`}
            style={{
              // eslint-disable-next-line
              backgroundImage: `url(${imageSrc ? imageSrc : ''})`,
              backgroundPosition: `center`,
              backgroundSize: `contain`
            }}
          />
        </div>
      )}
      <div className={`empty-state-header-${size}`}>
        {header ? (
          size === 'default' ? (
            <Typography variant="h3" color="brand-midnight">
              {header}
            </Typography>
          ) : (
            <Typography variant="smallRegular" color="brand-midnight">
              {header}
            </Typography>
          )
        ) : (
          ''
        )}
      </div>
      <div className={`empty-state-body-${size}`}>
        <p className={`empty-state-description-${size}`}>{description}</p>
      </div>
      {buttonText ? (
        <div className={`empty-state-action-${size}`}>
          <Button
            onClick={onClick}
            label={buttonText}
            disabled={buttonDisabled}
            icon={icon}
            size="small"
            type="primary"
          />
        </div>
      ) : null}
    </div>
  );
};

EmptyState.defaultProps = {
  size: 'default',
  imageSrc: undefined,
  buttonText: undefined,
  onClick: undefined,
  header: undefined
};
