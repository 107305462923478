import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface IProps {
  customClasses?: string;
}

export const Dimmer = ({ customClasses }: IProps) => {
  return <div className={classNames('dimmer', customClasses)} />;
};

export const DimmerWhite = ({ customClasses }: IProps) => {
  return (
    <div className={classNames('dimmer-white', customClasses)}>
      <div className="progress-spinner-default progress-spinner-color-gray" />
    </div>
  );
};

Dimmer.defaultProps = {
  customClasses: undefined
};
